import { inject } from "@angular/core";
import { LoggerService } from "../services/logger/logger.service";
import { BASE_CONFIG } from "./base-configs";

/**
 * The `ExceptionHandler` function is a higher-order function that returns a decorator for a method
 * that wraps the original method with error handling functionality. The decorator catches any exceptions
 * thrown by the decorated method, logs the error message, and rethrows the error.
 * 
 * @Example 
 * ```
    private _logger = inject(LoggerService);
 * ```
 * 
 * @Note The `_fileName` and `_logger` variables must be declared with the exact same name in the 
 * component or service file that uses the ExceptionHandler decorator.
 * 
 * @returns {Function} A decorator function that takes three parameters: `target`, `propertyKey`,
 * and `descriptor`.
 */
export function ExceptionHandler(
  canShowFuncName = true
): Function
{
  return function <T>(
    target: T,
    propertyKey: string,  // nothing but function name
    descriptor: PropertyDescriptor
  )
  {
    try
    {
      const originalMethod = descriptor.value;

      descriptor.value = function <T>(...args: T[])
      {
        const that = this;
        const fileName: string = that?.constructor.name;
        const logger: LoggerService = (that as any)['_logger'];

        if (!fileName)
          throw new Error(`The '_fileName' is not initialized. Please initialize it before use ${ propertyKey }.`);

        if (!logger)
          throw new Error(`The '_logger' dependency is not initialized. Please initialize it in ${ fileName } before use.`);

        try
        {
          if (BASE_CONFIG.IS_DEBUG && canShowFuncName) { console.log(`${ propertyKey } => ${ fileName }`); }

          return originalMethod.apply(this, args);
        }
        catch (error: unknown)
        {
          const errMsg = getErrorMsg(error);

          if (BASE_CONFIG.IS_DEBUG) { console.error(errMsg); }

          logger.logError(
            errMsg,
            fileName,
            propertyKey,
          );
        }
      };
    }
    catch (error)
    {
      const errMsg = getErrorMsg(error);

      if (BASE_CONFIG.IS_DEBUG) { console.error(errMsg); }

      const logger = inject(LoggerService);
      logger.logError(
        errMsg,
        'error-handler.ts',
        propertyKey,
      );
    }

    return descriptor;
  };
};

/**
 * The function `getErrorMsg` takes in an error, string, or unknown value
 * and returns a string error message.
 * @param {Error | string | unknown} err - The parameter `err` is of type `Error | string | unknown`,
 * which means it can accept an instance of the `Error` class, a string, or any other type of value.
 * @returns A string containing the error message.
 */
export const getErrorMsg = (
  err: Error | string | unknown
): string =>
{
  let errMsg!: string;

  if (err instanceof Error)
  {
    errMsg = err.message;
  }
  else if (typeof err === "string")
  {
    errMsg = err;
  }
  else
  {
    errMsg = String(err);
  }

  return errMsg;
};

