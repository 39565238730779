import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { ClearStoreFunction } from './core/store/clearStore';
import { LoggerService } from './services/logger/logger.service';
import { SaveAppVersionAction } from './store/common/common.action';
import { getAppVersion } from './store/common/common.selector';
import { AppProviders } from './util/app-providers';
import { BASE_CONFIG } from './util/base-configs';
import { ExceptionHandler } from './util/error-handler';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { KEEP_ALIVE } from './util/constant';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit
{
  private _logger = inject(LoggerService);

  private idleState: string = "NOT_STARTED";
  private countdown: number | null = null;
  private lastPing: Date | null = null;

  constructor(
    private http: HttpClient,
    private appProviders: AppProviders,
    private store: Store,
    private clearStore: ClearStoreFunction,
    private idle: Idle,
  )
  {
    this.initializeApp();
  }

  @ExceptionHandler()
  private initializeApp()
  {
    console.log(`%cv${ environment.version }`, "font-size: 1.5vw;color: #2599d8;");

    this.appIdleDetect();

    this.clearLocalStore();

    document.body.className = BASE_CONFIG.LANGUAGE;

    this.appProviders.setProvidersInStore();

    GoogleAuth.initialize({
      clientId: environment.clientId,
      scopes: environment.scopes,
      grantOfflineAccess: environment.grantOfflineAccess,
    });
  }

  @ExceptionHandler()
  ngOnInit(): void
  {
    this.reset();
  }

  @ExceptionHandler()
  private clearLocalStore()
  {
    const VERSION = environment.version;

    const version$ = this.store.select(getAppVersion())
      .subscribe(_version =>
      {
        if (!_version || _version !== VERSION)
        {
          this.clearStore.toClearStore();
        }
      });

    version$.unsubscribe();

    this.store.dispatch(new SaveAppVersionAction(VERSION));
  }


  @ExceptionHandler()
  private languageLoad()
  {
    this.http.get('./../locale/messages.' + BASE_CONFIG.LANGUAGE + '.json')
      .subscribe((res) =>
      {
        console.log(res);
        // loadTranslations(data);
      });
    // const data = require('./../locale/messages.' + BASE_CONFIG.LANGUAGE + '.json').translations;
  }

  @ExceptionHandler()
  reset()
  {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  @ExceptionHandler()
  appIdleDetect()
  {
    let retValue;
    this.idle.setIdle(KEEP_ALIVE.IDLE_COUNT); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(KEEP_ALIVE.TIMEOUT_COUNT); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    /* do something when the user becomes idle*/
    this.idle.onIdleStart.subscribe(() =>
    {
      this.idleState = KEEP_ALIVE.IDLE;
    });
    /* do something when the user is no longer idle*/
    this.idle.onIdleEnd.subscribe(() =>
    {
      this.idleState = KEEP_ALIVE.NOT_IDLE;
      this.countdown = null;
    });
    /*  do something when the user has timed out */
    this.idle.onTimeout.subscribe(() =>
    {
      this.idleState = KEEP_ALIVE.TIMED_OUT;
      // console.log('TIMED_OUT')
    });
    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe((seconds) =>
    {
      this.countdown = seconds;
    });

    /* set keepalive parameters, omit if not using keepalive */
    // this.keepalive.interval(2); // will ping at this interval while not idle, in seconds
    // this.keepalive.onPing.subscribe(() =>{
    //  this.lastPing = new Date();
    // }
    //  ); //
    let that = this;

    /* Checking if the browser tab is active or not. If the tab is active and idled for mentioned secs, it will reload
    the page. */
    document.addEventListener("visibilitychange", function ()
    {
      if (!document.hidden)
      {
        if (that.idleState == KEEP_ALIVE.TIMED_OUT)
        {
          window.location.reload();
        }
      }
    });
    return retValue;
  }
}
