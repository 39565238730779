import { Component, inject } from '@angular/core';
import { Provider } from 'types-magic-api/dist/src/index.model';
import { ToastTypes } from 'src/app/model/alert.model';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ExceptionHandler } from 'src/app/util/error-handler';
import { UtilFunctions } from 'src/app/util/utils';

@Component({
  selector: 'app-providers-modal',
  templateUrl: './providers-modal.component.html',
  styleUrls: ['./providers-modal.component.scss'],
})
export class ProvidersModalComponent
{
  private _logger = inject(LoggerService);

  protected providers: Provider[] = [];

  constructor(
    private util: UtilFunctions
  ) { }

  @ExceptionHandler()
  protected handleItemSelect(
    selProvider: Provider
  )
  {

    const foundProvider = selProvider;

    if (!foundProvider?.link)
    {
      const errMsg = $localize`:provider_modal_comp-providerLinkMissing:Provider redirect url not found`;

      this.util.showToast(errMsg, ToastTypes.Failure);
      throw new Error(errMsg);
    }

    this.util.openExternalLink(foundProvider.link);

    this.util.closeModal();
  }
}
