import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { ResetColorsAction, SaveColorsAction } from "./colors.action";
import { ColorsModel } from "src/app/model/colors.model";


const COLOR_SETTINGS = new StateToken<ColorsModel>('Colors');

@State({
  name: COLOR_SETTINGS,
  defaults: null
})

@Injectable()
export class ColorsState 
{
  constructor() { }

  @Action(SaveColorsAction)
  saveColorSettings(
    ctx: StateContext<ColorsModel>,
    action: SaveColorsAction
  )
  {
    ctx.setState(action.colors);
  }


  @Action(ResetColorsAction)
  resetSettings(
    ctx: StateContext<null>
  )
  {
    ctx.setState(null);
  }
}