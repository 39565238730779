import { SettingsModel } from "src/app/model/settings.model";
import { ActionTypes } from "src/app/util/constant";

export class SettingsAction
{
    static readonly type = ActionTypes.SaveSettings;

    constructor(
        public settings: SettingsModel
    ) { }
}

export class ResetSettingsAction
{
    static readonly type = ActionTypes.ResetSettings;
}
