import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { ResetSettingsAction, SettingsAction } from "./settings.action";
import { SettingsModel } from "src/app/model/settings.model";


const SETTINGS = new StateToken<SettingsModel>('Settings');

@State({
  name: SETTINGS,
  defaults: null
})

@Injectable()
export class SettingsState 
{
  @Action(SettingsAction)
  saveSettings(
    ctx: StateContext<SettingsModel>,
    action: SettingsAction
  )
  {
    ctx.setState(action.settings);
  }

  @Action(ResetSettingsAction)
  resetSettings(
    ctx: StateContext<null>
  )
  {
    ctx.setState(null);
  }
}
