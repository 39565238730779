import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DropdownComponent } from './dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [DropdownComponent],
  exports: [DropdownComponent],
  imports: [
    SharedModule,
    NgSelectModule
  ]
})
export class DropdownModule { }
