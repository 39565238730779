import { ColorsModel } from "src/app/model/colors.model";
import { ActionTypes } from "src/app/util/constant";

export class SaveColorsAction
{
  static readonly type = ActionTypes.SaveColors;

  constructor(
    public colors: ColorsModel
  ) { }
}

export class ResetColorsAction
{
  static readonly type = ActionTypes.ResetColors;
  constructor() { }
}
