import { EventEmitter, Injectable, inject } from "@angular/core";
import { ExceptionHandler } from "src/app/util/error-handler";
import { LoggerService } from "../logger/logger.service";
@Injectable({
  providedIn: 'root'
})
export class HeaderService
{
  private _logger = inject(LoggerService);

  constructor() { }

  private save = new EventEmitter<boolean | undefined>();
  private clear = new EventEmitter<boolean | undefined>();
  private profile = new EventEmitter<boolean | undefined>();
  private pageLoading = new EventEmitter<boolean>();

  @ExceptionHandler()
  emitOnSave(
    isSaved: boolean
  )
  {
    this.save.emit(isSaved);
  }

  @ExceptionHandler()
  emitOnClear(
    isCanceled: boolean
  )
  {
    this.clear.emit(isCanceled);
  }

  @ExceptionHandler()
  getSaveEmitter()
  {
    return this.save;
  }

  @ExceptionHandler()
  getClearEmitter()
  {
    return this.clear;
  }


  @ExceptionHandler()
  emitOnProfileChange(
    isChange: boolean
  )
  {
    this.profile.emit(isChange);
  }


  @ExceptionHandler()
  getProfileEmitter()
  {
    return this.profile;
  }


  @ExceptionHandler()
  emitOnPageFinishLoading(
    isFinishLoading: boolean
  )
  {
    this.pageLoading.emit(isFinishLoading);
  }


  @ExceptionHandler()
  getPageFinishLoadingEmitter()
  {
    return this.pageLoading;
  }

}