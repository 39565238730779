import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ngxsImports } from './store/store';
import { HttpClientModule } from '@angular/common/http';


const throwIfAlreadyLoaded = (
  parentModule: CoreModule,
  moduleName: string
) =>
{
  if (!parentModule) { return; }

  throw new Error(`${ moduleName } has already been loaded. Import Core modules in the AppModule only.`);
};



@NgModule({
  imports: [
    ...ngxsImports,
    CommonModule,
    HttpClientModule
  ]
})
export class CoreModule
{
  constructor(@Optional() @SkipSelf() parentModule: CoreModule)
  {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule>
  {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}
