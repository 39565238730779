import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { ResetUserMenusAction, SaveUserDeletedMenusAction, SaveUserMenusAction, SaveUserMenusSettingsAction } from "./menu.action";
import { UserMenu } from "src/app/model/menu.model";
import { UtilFunctions } from "src/app/util/utils";


const initialState: UserMenu = {
  userMenu: null,
  userDeletedMenus: [],
  userMenuSettings: {
    color: null,
    iconStyle: null,
    menuStyle: null,
    titleAlignment: null
  }
};

const MENUS = new StateToken<UserMenu>('Menus');

@State({
  name: MENUS,
  defaults: initialState
})

@Injectable()
export class MenuState
{
  constructor(
    private util: UtilFunctions
  ) { }

  @Action(SaveUserMenusAction)
  saveMenus(
    ctx: StateContext<UserMenu>,
    action: SaveUserMenusAction
  )
  {
    ctx.patchState({ userMenu: action.menus });
  }

  @Action(SaveUserMenusSettingsAction)
  saveMenuSettings(
    ctx: StateContext<UserMenu>,
    action: SaveUserMenusSettingsAction
  )
  {
    ctx.patchState({ userMenuSettings: action.menuSettings });
  }


  @Action(SaveUserDeletedMenusAction)
  saveDeletedMenus(
    ctx: StateContext<UserMenu>,
    action: SaveUserDeletedMenusAction
  )
  {
    const state = this.util.JSONClone(ctx.getState());

    state.userDeletedMenus.push(action.deletedMenu);

    ctx.setState(state);
  }


  @Action(ResetUserMenusAction)
  resetMenus(
    ctx: StateContext<UserMenu>
  )
  {
    ctx.patchState(initialState);
  }

}