import { environment } from '../../environments/environment';

export class BASE_CONFIG
{
  static readonly IS_DEBUG = !environment.production;
  static readonly LOGGER_URL = environment.loggerUrl;
  static readonly LOG_APP_ID = environment.logAppId;
  static readonly VERSION = environment.version;
  static readonly MAX_COMPONENT_LENGTH = 20;
  static readonly AWS_BASE_URL = environment.awsBase;
  static readonly PAYMENT_URL = environment.paymentUrl;

  static BROWSER = '';
  static IS_WEB = true;
  static NETWORK_STATUS = false;
  static PLATFORM = '';
  static LANGUAGE = 'en';
  static orgId = '';
}