import { createSelector } from "@ngxs/store";
import { AuthState } from "./auth.state";
import { AuthModel } from "src/app/model/auth.model";


export const getTokens = () =>
  createSelector([AuthState], (state: AuthModel) => state.tokens);


export const getUserDetails = () =>
  createSelector([AuthState], (state: AuthModel) => state.userDetails);

