import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { ResetScreenAction, SaveScreenAction } from "./screens.action";
import { AppScreenSettings } from "types-magic-api/dist/src/index.model";


const SETTINGS = new StateToken<AppScreenSettings>('Screen');

@State({
  name: SETTINGS,
  defaults: null
})

@Injectable()
export class ScreenState 
{
  @Action(SaveScreenAction)
  saveScreen(
    ctx: StateContext<AppScreenSettings>,
    action: SaveScreenAction
  )
  {
    ctx.setState(action.screenSettings);
  }

  @Action(ResetScreenAction)
  resetScreen(
    ctx: StateContext<null>
  )
  {
    ctx.setState(null);
  }
}
