import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { AuthTokenAction, ProviderTokenAction, UserDetailAction } from "./auth.actions";
import { UtilFunctions } from "src/app/util/utils";
import { AuthModel } from "src/app/model/auth.model";


export const initialAuthState: AuthModel = {
  userDetails: null,
  tokens: null
};

export const STATE_TOKEN = new StateToken<AuthModel>('auth');

@State({
  name: STATE_TOKEN,
  defaults: initialAuthState,
})

@Injectable()
export class AuthState
{
  constructor(
    private util: UtilFunctions
  ) { }


  @Action(AuthTokenAction)
  updateAuthTokens(
    ctx: StateContext<AuthModel>,
    action: AuthTokenAction
  )
  {
    const state = this.util.cloneDeep(ctx.getState());

    state.tokens = {
      authToken: action.authToken,
      providerToken: state.tokens?.providerToken ?? null
    };

    ctx.patchState(state);
  }

  @Action(ProviderTokenAction)
  updateProviderToken(
    ctx: StateContext<AuthModel>,
    action: ProviderTokenAction
  )
  {
    const state = this.util.cloneDeep(ctx.getState());

    state.tokens = {
      providerToken: action.providerToken,
      authToken: state.tokens?.authToken ?? null
    };

    ctx.patchState(state);
  }

  @Action(UserDetailAction)
  setUserDetails(
    ctx: StateContext<AuthModel>,
    action: UserDetailAction
  )
  {
    ctx.patchState(
      {
        userDetails: action.user
      }
    );
  }
}
