export enum LOG_TYPE
{
  CRITICAL = "critical",
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
}

export interface LoggerRequest
{
  appId: string;
  browser: string;
  browserversion: string;
  errtitle: string;
  functionname: string;
  level: LOG_TYPE;
  message: string;
  module: string;
  ostype: string;
  pathName: string;
  timestamp: string;
  versioncode: string;
}

export interface ErrorHandlerRequest
{
  errMsg: string;
  fileName: string;
  functionName: string;
}

export interface LoggerModel
{
  logRequest: LoggerRequest | null;
} 