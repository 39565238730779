import { AppMenuObj, MenuSettings } from "src/app/model/menu.model";
import { ActionTypes } from "src/app/util/constant";

export class SaveUserMenusAction
{
    static readonly type = ActionTypes.SaveMenu;
    constructor(public menus: AppMenuObj[]) { }
}

export class SaveUserDeletedMenusAction
{
    static readonly type = ActionTypes.SaveDeletedMenu;
    constructor(public deletedMenu: string) { }
}

export class SaveUserMenusSettingsAction
{
    static readonly type = ActionTypes.SaveMenuSettings;
    constructor(public menuSettings: MenuSettings) { }
}

export class ResetUserMenusAction
{
    static readonly type = ActionTypes.ResetMenu;
    constructor() { }
}