export enum Status
{
  Success = "Success",
  Error = "Error",
  ERROR_JWT = "jwt",
  ERROR_JWT_01 = "JWT01",
  ERROR_JWT_02 = "JWT02",
  ERROR_PER = "PER01",
  ERROR_AUT2 = "AUT2",
}

export interface SuccessResponse<T>
{
  status: Status.Success;
  data: T;
}

export interface ErrorResponse<T>
{
  status: Status.Error;
  error: any;
  data: T;
}

export interface ErrorAlertVal
{
  codeList: Array<String>,
  valueList: Array<String>;
}


export type APIResponse<T> = SuccessResponse<T> | ErrorResponse<T>;
