import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { CommonActionModal } from "src/app/model/common.modal";
import { FileUploadAction, ResetCommonAction, SaveAppVersionAction, SaveProvidersAction, UpdateIntroVisitAction, UpdateIsNewUserAction, UpdateTourVisitAction } from "./common.action";


const initialCommonValue: CommonActionModal = {
  isIntroVisited: false,
  isNewUser: false,
  isTourVisited: false,
  providers: [],
  version: null,
  fileUpload: null,
};


const COMMON = new StateToken<CommonActionModal>('Common');

@State({
  name: COMMON,
  defaults: initialCommonValue
})


@Injectable()
export class CommonState 
{
  @Action(UpdateIntroVisitAction)
  updateIntroVisit(
    ctx: StateContext<CommonActionModal>,
    action: UpdateIntroVisitAction
  )
  {
    ctx.patchState({ isIntroVisited: action.isVisited });
  }


  @Action(UpdateIsNewUserAction)
  updateIsNewUser(
    ctx: StateContext<CommonActionModal>,
    action: UpdateIsNewUserAction
  )
  {
    ctx.patchState({ isNewUser: action.isNewUser });
  }


  @Action(UpdateTourVisitAction)
  updateTourVisit(
    ctx: StateContext<CommonActionModal>,
    action: UpdateTourVisitAction
  )
  {
    ctx.patchState({ isTourVisited: action.isVisited });
  }


  @Action(SaveProvidersAction)
  saveProviders(
    ctx: StateContext<CommonActionModal>,
    action: SaveProvidersAction
  )
  {
    ctx.patchState({ providers: action.providers });
  }


  @Action(ResetCommonAction)
  resetCommon(
    ctx: StateContext<CommonActionModal>
  )
  {
    ctx.setState(initialCommonValue);
  }


  @Action(SaveAppVersionAction)
  updateVersion(
    ctx: StateContext<CommonActionModal>,
    action: SaveAppVersionAction
  )
  {
    ctx.patchState({ version: action.version });
  }

  @Action(FileUploadAction)
  updateFileUpload(
    ctx: StateContext<CommonActionModal>,
    action: FileUploadAction
  )
  {
    ctx.patchState({ fileUpload: action.fileUpload });
  }
}
