import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { FirebaseAuthentication } from '@awesome-cordova-plugins/firebase-authentication';
import { Store } from '@ngxs/store';
import 'firebase/auth';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable, from, switchMap } from 'rxjs';
import { Tokens } from 'src/app/model/auth.model';
import { AuthTokenAction } from 'src/app/store/auth/auth.actions';
import { getTokens } from 'src/app/store/auth/auth.selectors';
import { BASE_CONFIG } from 'src/app/util/base-configs';
import { ADMIN_SERVICE_URL, SERVICE_URL, ZACE } from 'src/app/util/constant';
import { ExceptionHandler } from 'src/app/util/error-handler';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../logger/logger.service';
import { getAdminTokens } from 'src/app/store/auth-admin/auth-admin.selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor
{
  private _logger = inject(LoggerService);

  tokenSubject = new BehaviorSubject<Tokens | null>(null);

  private readonly BASE_URL = environment.apiUrl;
  private readonly isWeb = BASE_CONFIG.IS_WEB;

  private readonly excludedURLs = [
    // this.BASE_URL + SERVICE_URL.APP_AUTH_URL,
    this.BASE_URL + SERVICE_URL.LOGOUT,
    this.BASE_URL + SERVICE_URL.GET_ALL_PROVIDER,
    this.BASE_URL + SERVICE_URL.UNSUBSCRIBE_MAIL,
    ZACE.BASE_URL + SERVICE_URL.SAVE_PUBLIC_EMAIL,
  ];

  private readonly adminURLs = [
    this.BASE_URL + ADMIN_SERVICE_URL.GET_ALL_APP,
    this.BASE_URL + ADMIN_SERVICE_URL.GET_ALL_APP_STORE_BUILD_SUBSCRIBER,
    this.BASE_URL + ADMIN_SERVICE_URL.GET_ALL_APP_STORE_SUBSCRIBER,
    this.BASE_URL + ADMIN_SERVICE_URL.GET_ALL_APP_SUBSCRIBER,
    this.BASE_URL + ADMIN_SERVICE_URL.LOGIN,
    this.BASE_URL + ADMIN_SERVICE_URL.BUILD_STATUS_CHANGE,
  ];

  constructor(
    private store: Store,
  ) { }

  @ExceptionHandler()
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any | HttpEvent<any>>
  {
    if (this.adminURLs.includes(request.url))
    {
      this.tokenSubject.next(null);

      return from(this.getAdminToken())
        .pipe(
          switchMap((token) =>
          {
            if (token)
            {
              request = request.clone({
                setHeaders: {
                  'authorization': (token as string),
                }
              });
            }

            return next.handle(request);
          })
        );
    }

    const isExcludedAPI = this.excludedURLs.includes(request.url);

    if (request.method !== 'POST') { return next.handle(request); }

    if (isExcludedAPI)
    {
      return next.handle(request);
    }
    else
    {
      this.tokenSubject.next(null);

      return from(this.getToken(request.url))
        .pipe(
          switchMap((newToken) =>
          {
            if (
              newToken
              && newToken.authToken
              && newToken.providerToken
            )
            {
              this.tokenSubject.next(newToken);

              request = request.clone({
                setHeaders: {
                  'authorization': `Bearer ${ newToken.authToken }`,
                  'provider-authorization': newToken.providerToken
                }
              });
            }
            return next.handle(request);
          })
        );
    }
  }

  @ExceptionHandler()
  async getToken(url: string): Promise<Tokens>
  {
    if (this.isWeb)
    {
      return this.getWebToken(url);
    }
    else
    {
      return this.getFirebaseToken();
    }
  }

  @ExceptionHandler()
  getFirebaseToken(): Promise<Tokens>
  {
    return new Promise((resolve, reject) =>
    {
      FirebaseAuthentication.getCurrentUser().then(async (res) =>
      {
        if (res)
        {
          const idToken = await FirebaseAuthentication.getIdToken(true);
          this.store.dispatch(new AuthTokenAction(idToken));
          this.getTokenData(resolve, idToken);
        }
        else
        {
          const auth = firebase.auth();
          const unsubscribe = auth.onIdTokenChanged(user =>
          {
            unsubscribe();
            if (user)
            {
              user.getIdToken().then(token =>
              {
                this.store.dispatch(new AuthTokenAction(token));
                this.getTokenData(resolve, token);
              });
            }
            else
            {
              reject({ token: null, provToken: null });
            }
          }, reject);
        }
      });
    });
  }

  @ExceptionHandler()
  getWebToken(url: string): Promise<Tokens>
  {
    return new Promise((resolve, reject) =>
    {
      const auth = firebase.auth();
      const unsubscribe = auth.onIdTokenChanged(user =>
      {
        unsubscribe();
        if (user)
        {
          user.getIdToken()
            .then(token =>
            {
              if (url != this.BASE_URL + SERVICE_URL.APP_AUTH_URL)
              {
                this.store.dispatch(new AuthTokenAction(token));
              }
              this.getTokenData(resolve, token);
            });
        }
        else
        {
          reject(null);
        }
      }, reject);
    });
  }

  @ExceptionHandler()
  getTokenData(
    resolve: Function,
    authToken: string
  )
  {
    this.store.selectOnce(getTokens())
      .subscribe(tokens =>
      {
        const _tokens: Tokens = {
          authToken,
          providerToken: tokens?.providerToken ?? null
        };

        resolve(_tokens);
      });
  }

  @ExceptionHandler()
  getAdminToken()
  {
    return new Promise((resolve, reject) =>
    {
      this.store.selectOnce(getAdminTokens())
        .subscribe(tokens =>
        {
          resolve(tokens);
        });
    });
  }
}

