import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { ResetUserDeletedComponentsAction, StoreUserComponentsAction, StoreUserDeletedComponentsAction } from "./showcase.action";
import { ShowcaseItemsModel } from "src/app/model/components.model";
import { UtilFunctions } from "src/app/util/utils";

export const initialShowcaseItemsState: ShowcaseItemsModel = {
  userComponents: [],
  deletedComponents: []
};

export const SHOWCASE_ITEMS = new StateToken<ShowcaseItemsModel>('ShowcaseItems');

@State({
  name: SHOWCASE_ITEMS,
  defaults: initialShowcaseItemsState
})

@Injectable()
export class ShowcaseItemsState 
{
  constructor(
    private util: UtilFunctions
  ) { }


  @Action(StoreUserComponentsAction)
  saveUserComponents(
    ctx: StateContext<ShowcaseItemsModel>,
    action: StoreUserComponentsAction
  )
  {
    ctx.patchState({
      userComponents: action.userComponents
    });
  }


  @Action(StoreUserDeletedComponentsAction)
  saveUserDeletedComponents(
    ctx: StateContext<ShowcaseItemsModel>,
    action: StoreUserDeletedComponentsAction
  )
  {
    const state = this.util.JSONClone(ctx.getState());
    const deletedComponents = this.util.cloneDeep(state.deletedComponents) ?? [];

    deletedComponents.push(action.deletedId);

    ctx.patchState({ deletedComponents });
  }


  @Action(ResetUserDeletedComponentsAction)
  resetUserDeletedComponents(
    ctx: StateContext<ShowcaseItemsModel>,
    action: ResetUserDeletedComponentsAction
  )
  {
    ctx.patchState({ deletedComponents: [] });
  }

}
