import { User } from "src/app/model/user.model";
import { ActionTypes } from "src/app/util/constant";


export class AuthTokenAction 
{
  static readonly type = ActionTypes.SaveAuthTokenAction;
  constructor(
    public authToken: string | null
  ) { }
}

export class ProviderTokenAction 
{
  static readonly type = ActionTypes.SaveProviderTokenAction;
  constructor(
    public providerToken: string | null
  ) { }
}


export class UserDetailAction
{
  static readonly type = ActionTypes.SaveUserDetailAction;
  constructor(
    public user: User | null
  ) { }
}

