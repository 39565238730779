import { Provider } from "types-magic-api/dist/src/index.model";
import { ActionTypes } from "src/app/util/constant";
import { FileUpload } from "src/app/model/file-uploader.model";

export class UpdateIntroVisitAction
{
    static readonly type = ActionTypes.UpdateIntroVisitAction;

    constructor(public isVisited: boolean) { }
}

export class UpdateIsNewUserAction
{
    static readonly type = ActionTypes.UpdateIsNewUserAction;

    constructor(public isNewUser: boolean) { }
}

export class UpdateTourVisitAction
{
    static readonly type = ActionTypes.UpdateTourVisitAction;

    constructor(public isVisited: boolean) { }
}

export class SaveProvidersAction
{
    static readonly type = ActionTypes.SaveProvidersAction;

    constructor(public providers: Provider[]) { }
}

export class SaveAppVersionAction
{
    static readonly type = ActionTypes.SaveAppVersionAction;

    constructor(public version: string) { }
}

export class FileUploadAction
{
    static readonly type = ActionTypes.FileUploadAction;

    constructor(public fileUpload: FileUpload<undefined>) { }
}

export class ResetCommonAction
{
    static readonly type = ActionTypes.ResetCommonAction;
}

