import { IUserComponent } from "src/app/pages/home/design/showcase/model";
import { ActionTypes } from "src/app/util/constant";


export class StoreUserComponentsAction
{
  static readonly type = ActionTypes.StoreUserComponents;

  constructor(
    public userComponents: IUserComponent[]
  ) { }
}

export class StoreUserDeletedComponentsAction
{
  static readonly type = ActionTypes.StoreUserDeletedComponents;

  constructor(public deletedId: string) { }
}

export class ResetUserDeletedComponentsAction
{
  static readonly type = ActionTypes.ResetUserDeletedComponents;

  constructor() { }
}