import { IMenuItemDefault } from '../model/menu.model';

export class APP_ROUTES
{
  static readonly HOME = '';
  static readonly AUTH = 'auth';
  static readonly LANDING = 'landing';
  static readonly FORGET_PASSWORD = 'auth/forget-password';
  static readonly LOGIN = 'auth/login';
  static readonly REGISTER = 'auth/register';

  static readonly DESIGN = 'design';
  static readonly DEEP_LINK = 'design/deepLink';
  static readonly MENU = 'design/menu';
  static readonly SETTINGS = 'design/settings';
  static readonly COLORS = 'design/colors';
  static readonly SHOWCASE = 'design/showcase';
  static readonly THEMES = 'design/themes';
  static readonly SCREENS = 'design/screens';
  static readonly PROFILE = 'design/profile';
  static readonly PUBLISH = 'design/publish';
  static readonly ACCOUNT_AND_BILLING = 'design/billing';

  static readonly CONTENT = 'content';
  static readonly PRODUCT_UPLOAD = 'content/upload';
  static readonly UNSUBSCRIBE = 'unsubscribe';
  static readonly GUIDE = 'guide';
  static readonly ONBOARD = 'onboard';

  static readonly ADMIN = 'ascent/technologies/wov/admin';
  static readonly ADMIN_LOGIN = 'ascent/technologies/wov/admin/auth/login';
  static readonly APP_SELECTION = 'ascent/technologies/wov/admin/app-selection';
  static readonly USER_APP_DETAILS = 'ascent/technologies/wov/admin/app-detail';
  static readonly DELETE = 'delete';
  /*
   * Payment stripe
   */
  static readonly PAYMENT_GATEWAY = '/processpayment/entry';
}

export class SERVICE_URL
{
  //auth
  static readonly APP_AUTH_URL = 'api/auth/firebase';
  static readonly LOGOUT = 'api/auth/logout';

  //update
  static readonly UPDATE_APP_COLOR_SETTINGS = 'api/app/color/setting/update';
  static readonly UPDATE_APP_SETTINGS = 'api/app/setting/update';
  static readonly UPDATE_APP_SCREEN_SETTINGS = 'api/app/screen/save';
  static readonly UPDATE_PROFILE = 'api/admin/profile/update';

  //save
  static readonly SAVE_COMPONENTS = 'api/app/component/save';
  static readonly SAVE_USER_MENU = 'api/app/menu/save';
  static readonly APPLY_THEME = 'api/theme/apply';

  // Delete
  static readonly DELETE_COMPONENTS = 'api/app/component/delete';
  static readonly DELETE_USER_MENU = 'api/app/menu/delete';

  //get
  static readonly GET_ALL_COLLECTIONS = 'api/admin/collection/get/all';
  static readonly GET_ALL_COLLECTION_BY_ID = 'api/admin/collection/get/all/by/id';
  static readonly GET_ALL_COMPONENTS = 'api/default/component/get/all';
  static readonly GET_ALL_DEFAULT_MENU = 'api/default/menu/get/all';
  static readonly GET_ALL_PRODUCT = 'api/admin/product/get/all';
  static readonly GET_ALL_PRODUCT_BY_IDS = 'api/admin/product/get/all/by/id';
  static readonly GET_ALL_PROVIDER = 'api/provider/get/all';
  static readonly GET_APP_COLOR_SETTINGS = 'api/app/color/setting/get';
  static readonly GET_APP_LANGUAGE = 'api/app/language/get';
  static readonly GET_APP_SCREENS = 'api/app/screen/get';
  static readonly GET_APP_SETTINGS = 'api/app/setting/get';
  static readonly GET_COMPONENTS = 'api/app/component/get';
  static readonly GET_COUNTRIES = 'api/country/get';
  static readonly GET_LANGUAGE = 'api/language/get';
  static readonly GET_PROFILE = 'api/admin/profile/get';
  static readonly GET_QR = 'api/customer/qr/get';
  static readonly GET_TIMEZONE = 'api/timezone/get';
  static readonly GET_USER_MENU = 'api/app/menu/get';
  static readonly GET_ALL_THEME = 'api/theme/get/all';
  static readonly THEME_PREVIEW_QR = 'api/theme/preview/qr';
  static readonly GET_PRODUCT_BY_COLLECTION_ID = 'api/admin/collection/product/get/all';
  static readonly GET_ALL_APP_BUILD = 'api/app/build/get/all';

  static readonly START_APP_BUILD = 'api/app/build/start';
  static readonly APP_PUBLISH = 'api/app/publish';
  static readonly CANCEL_APP_BUILD = 'api/app/build/cancel';
  static readonly REQUEST_APP_BUILD_TO_PUBLISH = 'api/user/app/release/request';

  // file upload
  static readonly FILE_UPLOAD = 'api/file/upload';
  static readonly VIDEO_SNAPSHOT = 'api/video/snapshot';

  static readonly UNSUBSCRIBE_MAIL = 'api/user/email/unsubscribe';

  static readonly GET_SUBSCRIPTION_DETAILS = 'api/payment/subscription/details';
  static readonly PAUSE_SUBSCRIPTION = '/api/saas/public/subscription/pause';
  static readonly UNPAUSE_SUBSCRIPTION = '/api/saas/public/subscription/unpause';
  static readonly RENEW_SUBSCRIPTION = 'api/payment/subscription/renew';
  static readonly CANCEL_SUBSCRIPTION = 'api/app/subscription/cancel';

  static readonly GET_SUBSCRIPTION_HISTORY = '/api/saas/public/subscription/history/get';
  static readonly GET_ALL_PLAN = 'api/plan/get/all';
  static readonly GET_BUY_PLAN = 'api/payment/buy';
  static readonly GET_PAYMENT_ORDER_STATUS = 'api/payment/order/status';
  static readonly SAVE_PUBLIC_EMAIL = 'api/public/email';
}

export class ADMIN_SERVICE_URL
{
  static readonly BUILD_STATUS_CHANGE = 'api/maintainer/app/build/status/change';
  static readonly GET_ALL_APP = 'api/maintainer/app/get/all';
  static readonly GET_ALL_APP_STORE_BUILD_SUBSCRIBER = 'api/maintainer/store/build/get/all';
  static readonly GET_ALL_APP_STORE_SUBSCRIBER = 'api/maintainer/app/subscriber/store/get/all';
  static readonly GET_ALL_APP_SUBSCRIBER = 'api/maintainer/app/subscriber/get/all';
  static readonly LOGIN = 'api/maintainer/login';
}

export enum PASSWORD_TYPE
{
  PASSWORD = 'password',
  TEXT = 'text',
}

export class SOCIAL_LOGIN_TYPE
{
  static readonly GOOGLE = 'google';
  static readonly TWITTER = 'twitter';
  static readonly FACEBOOK = 'facebook';
}

export enum FirebaseErrorMessage
{
  EMAIL_ALREADY_USED = 'auth/email-already-in-use',
  USER_NOT_FOUND = 'auth/user-not-found',
  WRONG_PASSWORD = 'auth/wrong-password',
}

export class FB_AUTH_TYPE
{
  static SIGN_IN_WITH_POPUP = 'signInWithPopup';
  static SIGN_IN_WITH_REDIRECT = 'signInWithRedirect';
  static SIGN_IN_WITH_EMAIL_AND_PW = 'signInWithEmailAndPassword';
}

export class AUTH_KEY
{
  static readonly ok = 'OK';
  static readonly error = 'ERROR';
  static readonly existingUser = 'existing_user_no_refresh';
  static readonly tokenExpired = 'token-expired';
  static readonly newUserRefresh = 'new_user-refresh';
}

export class PLATFORM
{
  static android = 'android';
  static ios = 'ios';
  static web = 'web';
}

export class ROUTER_PARAM
{
  static readonly SESSION_ID = 's';
  static readonly PARAM = 'param';
  static readonly REDIRECT_URL = 'redirect-url';
  static readonly INTRO = 'intro';
  static readonly PROVIDER_TOKEN = 'pt';
  static readonly UNSUBSCRIBE_TOKEN = 't';
  static readonly CODE = 'code';
  static readonly EMAIL = 'email';
  static readonly ERROR = 'error';
  static readonly TYPE_PARAM = '?type=';
  static readonly ONLY_TYPE_PARAM = 'type=';
  static readonly PLAN_ID_PARAM = '&planId=';
  static readonly USER_COUNT_PARAM = '&usersCount=';
  static readonly USED_USER_COUNT_PARAM = '&usedUserCount=';
  static readonly UPGRADE = 'upgrade';
  static readonly GATEWAY = '&gateway=';
  static readonly PLAN_TYPE = '&planType=';
  static readonly MODULE_CODES_PARAM = '&moduleCodes=';
  static readonly TOKEN_PARAM = '&authToken=';
  static ORG_ID = 'orgId';
  static SUBSCRIBER_ID = 'subscriberId';
  static APP_ID = 'appId';
}

export enum ScreenType
{
  PRODUCT_AND_SEARCH = '1',
  PRODUCT_DETAILS = '2',
  CART = '3',
  CHECKOUT = '4',
}

export enum ScreenTypes
{
  Product_Search_List = 1,
  Product_Details = 2,
  Cart = 3,
  Checkout = 4,
}

export enum AttributeTypes
{
  STRING = 'STRING',
  ENUM = 'ENUM',
  BOOLEAN = 'BOOLEAN',
  LABEL = 'LABEL',
  FILE = 'FILE',
  ICON_SELECT = 'ICON_SELECT',
  COLOR_PICKER = 'COLOR_PICKER',
  TIME_PICKER = 'TIME_PICKER',
  DISCOUNT_CODE = 'DISCOUNT_CODE',
  DROPDOWN = 'DROPDOWN',
}

export enum PROPERTY_NAMES
{
  LOCAL_ID = 'localID',
  COMP_ID = 'compID',
}

export enum MediaDimension
{
  AppIconHeight = 1024,
  AppIconWidth = 1024,
  AppSplashScreenHeight = 2688,
  AppSplashScreenWidth = 1242,
  AppTitleBannerHeight = 90,
  AppTitleBannerWidth = 450,
}

export enum MediaSize
{
  AppIcon = 2,
  AppSplashScreen = 5,
  AppTitleBanner = 5,
}

export enum ToggleType
{
  Branding = 'Branding',
  GuestCheckout = 'GuestCheckout',
  HolidayEffect = 'HolidayEffect',
}

export enum ShapeType
{
  CURVE = 'CURVE',
  ROUND = 'ROUND',
  SQUARE = 'SQUARE',
}

export enum ContentType
{
  PRODUCT = 'PRODUCT',
  COLLECTION = 'COLLECTION',
  WEB_URL = 'WEB_URL',
}

export enum DisplayType
{
  SQUARE = 'SQUARE',
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  AUTO = 'AUTO',
}

export enum SizeType
{
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum TitleAlignment
{
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export enum TitlePosition
{
  INNER = 'INNER',
  OUTER = 'OUTER',
  HIDDEN = 'HIDDEN',
}

export enum DividerType
{
  EMPTY = 'EMPTY',
  ZIG_ZAG = 'ZIG_ZAG',
  WAVE = 'WAVE',
  STRAIGHT = 'STRAIGHT',
  DASH = 'DASH',
  // CUSTOM = 'CUSTOM'
}

export enum ComponentTypes
{
  CAROUSEL = 'CAROUSEL',
  CIRCLE = 'CIRCLE',
  COUNTDOWN_TIMER = 'COUNTDOWN_TIMER',
  DISCOUNT = 'DISCOUNT',
  DIVIDER = 'DIVIDER',
  GALLERY = 'GALLERY',
  GRID = 'GRID',
  IMAGE = 'IMAGE',
  RECENTLY_VIEWED = 'RECENTLY_VIEWED',
  SEARCH_BAR = 'SEARCH_BAR',
  SLIDER = 'SLIDER',
  SLIDESHOW = 'SLIDESHOW',
  TITLE = 'TITLE',
  VIDEO_PLAYER = 'VIDEO_PLAYER',
}

export enum ComponentProperty
{
  ACTION_TEXT = 'actionText',
  ADD_TO_CART = 'addToCart',
  AUTO_PLAY = 'autoPlay',
  BAR_TYPE = 'barType',
  CLICKABLE = 'clickable',
  CONTENT_TYPE = 'contentType',
  CONTROL_BAR = 'controlBar',
  CUSTOMER_DISCOUNT_CODE = 'customerDiscountCode',
  DISCOUNT_CODE = 'discountCode',
  DISCOUNT_IMAGE = 'discountImage',
  DISPLAY_TYPE = 'displayType',
  DIVIDER_TYPE = 'dividerType',
  HAS_ACTION_TEXT = 'hasActionText',
  HAS_ITEM_MARGIN = 'hasItemMargin',
  HAS_MARGIN = 'hasMargin',
  HIDE_BORDER = 'hideBorder',
  HIDE_WHEN_FINISH = 'hideWhenFinish',
  IS_NEED_LINK = 'isNeedLink',
  LINK_TO = 'linkTo',
  LOOP = 'loop',
  MUTE_BTN = 'muteBtn',
  PLACEHOLDER = 'placeholder',
  PROMOTION_IMAGE = 'promotionImage',
  RESTART_BTN = 'restartBtn',
  SHOW_ITEM_TITLE = 'showItemTitle',
  SHOW_PRICE = 'showPrice',
  SHOW_TITLE = 'showTitle',
  THUMBNAIL_IMAGE = 'thumbnailImage',
  TIMER_EXPIRE_DATE = 'timerExpireDate',
  TIMER_NUMBER_COLOR = 'timerNumberColor',
  TIMER_TIMEZONE = 'timerTimezone',
  TITLE = 'title',
  TITLE_ALIGNMENT = 'titleAlignment',
  TITLE_POSITION = 'titlePosition',
  VIDEO_DISPLAY_TYPE = 'videoDisplayType',
  VIDEO_UPLOAD = 'videoUpload',
  VIEW_SIZE = 'viewSize',
}

export enum ComponentIcon
{
  CAROUSEL = 'accessibility-outline',
  CIRCLE = 'accessibility-outline',
  COUNTDOWN_TIMER = 'accessibility-outline',
  DISCOUNT = 'accessibility-outline',
  DIVIDER = 'accessibility-outline',
  GALLERY = 'accessibility-outline',
  GRID = 'accessibility-outline',
  IMAGE = 'accessibility-outline',
  RECENTLY_VIEWED = 'accessibility-outline',
  SEARCH_BAR = 'accessibility-outline',
  SLIDER = 'accessibility-outline',
  SLIDESHOW = 'accessibility-outline',
  TITLE = 'accessibility-outline',
  VIDEO_PLAYER = 'accessibility-outline',
}

export enum MenuIcon
{
  SHOWCASE = 'accessibility-outline',
  MY_ORDERS = 'accessibility-outline',
  // NOTIFICATION = "accessibility-outline",
  SEARCH = 'accessibility-outline',
  MY_ACCOUNT = 'accessibility-outline',
  WEB_URL = 'accessibility-outline',
  CART = 'accessibility-outline',
  HOME_PAGE = 'accessibility-outline',
  ALL_PRODUCT = 'accessibility-outline',
  RECENTLY_VIEWED = 'accessibility-outline',
  FAVORITES = 'accessibility-outline',
  GROUP = 'accessibility-outline',
  CONTACT = 'accessibility-outline',
  MOBILE_APP_PRIVACY_POLICY = 'accessibility-outline',
  STYLE = 'accessibility-outline',
  COLOR = 'accessibility-outline',
}

export enum FileUploadId
{
  APP_ICON = 'appIcons',
  APP_SPLASH_SCREEN = 'appSplashScreen',
  APP_TITLE_HEADER_BANNER = 'appTitleHeaderBanner',
}

export enum ActionTypes
{
  ResetColors = '[Color Page] Reset Colors',
  ResetCommonAction = '[Common] Reset Common Action',
  ResetDefaultMenu = '[Menu Page] Reset Default Menu',
  ResetLanguage = '[Settings Page] Reset Language',
  ResetMenu = '[Menu Page] Reset Items',
  ResetScreen = '[Screen Page] Reset Screen',
  ResetSettings = '[Settings Page] Reset Settings',
  ResetShowcaseItems = '[Reset Showcase Component] Reset Showcase Items',
  SaveAppVersionAction = '[Common] Save App Version',
  SaveAuthTokenAction = '[token] set token',
  SaveAdminAuthTokenAction = '[token] set admin token',
  SaveColors = '[Color Page] Save Colors',
  SaveLanguage = '[Settings Page] Save Language',
  SaveMenu = '[Menu Page] Save Items',
  SaveDeletedMenu = '[Menu Page] Save Deleted Items',
  SaveMenuSettings = '[Menu Page] Save Menu Settings',
  SaveProviderTokenAction = '[token] set provider token',
  SaveProvidersAction = '[Common] Save Providers Action',
  SaveScreen = '[Screen Page] Save Screen',
  SaveSettings = '[Settings Page] Save Settings',
  SaveUserDetailAction = '[userDetail] state',
  SetDefaultMenu = '[Menu Page] Set Default Menu',
  ShowcaseItems = '[Showcase Component] Showcase Items',
  StoreUserComponents = '[Showcase Component] Store User Components',
  StoreUserDeletedComponents = '[Showcase Component] Store User Deleted Components',
  ResetUserDeletedComponents = '[Showcase Component] Reset User Deleted Components',
  UpdateIntroVisitAction = '[Common] Update Intro Slide',
  UpdateIsNewUserAction = '[Common] Update New User',
  UpdateTourVisitAction = '[Common] Update Tour Slide',
  FileUploadAction = '[Common] File Upload',
}

export class Defaults
{
  static readonly actionPH = $localize`:title_page-defaultActionPH:Enter Your Action Text`;
  static readonly appName = $localize`:@@const-appName:Magic App`;
  static readonly customerCodePH = $localize`:title_page-defaultTitlePH:Enter Your Discount Code`;
  static readonly linkPH = $localize`:title_page-defaultLinkPH:Enter Your Link`;
  static readonly title = $localize`:title_page-defaultTitle:Your Title`;
  static readonly titlePH = $localize`:title_page-defaultTitlePH:Enter Your Title`;
  static readonly language = 'en';
  static readonly timeZone = 'Asia/Kolkata';
}

export class DEFAULT_COLOR
{
  static readonly primaryColor = '#0080FF';
  static readonly secondaryColor = '#E6FFF9';
  static readonly cartColor = '#00A329';
  static readonly timerNumberColor = '#000';
  static readonly menuColor = '#000';
}

export enum ScreenSegment
{
  PRODUCT_AND_SEARCH = 'PRODUCT_AND_SEARCH',
  PRODUCT = 'PRODUCT_DETAILS',
  CART = 'CART',
}

export enum ScreenSegmentType
{
  AUTO_NAV_TO_CART = 'autoNavToCart',
  AUTO_SELECT_VARIANT = 'autoSelectVariant',
  DECIMAL_PLACES = 'decimalPlaces',
  DISABLE_CHECKOUT = 'disableCheckout',
  DISPLAY_BRAND_NAME = 'displayBrandName',
  HIDE_OUT_OF_PRODUCTS = 'hideOutOfProducts',
  HIDE_SHIPPING_RATE = 'hideShippingRate',
  MINIMUM_CART_VALUE = 'minimumCartValue',
  ORDER_NOTE = 'orderNote',
  SHOW_DISCOUNT_PERCENTAGE = 'showDiscountPercentage',
  SHOW_PRODUCT_MODEL_NUMBER = 'showProductModelNumber',
  SHOW_RECENTLY_VIEWED = 'showRecentlyViewed',
  SHOW_SUB_COLLECTION = 'showSubCollection',
  SHOW_VARIANT_IMG = 'showVariantImg',
  SHOW_VENDOR_NAME = 'showVendorName',
  SKIP_LOGIN = 'skipLogin',
  SORT_FUNCTION = 'sortFunction',
  SHOW_QUANTITY = 'showQuantity',
  SHOW_STOCK_KEEPING_UNIT = 'showStockKeepingUnit',
}

export enum MenuType
{
  ALL_PRODUCT = 'ALL_PRODUCT',
  CART = 'CART',
  // COLLECTION = "COLLECTION",
  // CONTACT = "CONTACT",
  FAVORITES = 'FAVORITES',
  GROUP = 'GROUP',
  HOME_PAGE = 'HOME_PAGE',
  MOBILE_APP_PRIVACY_POLICY = 'MOBILE_APP_PRIVACY_POLICY',
  MY_ACCOUNT = 'MY_ACCOUNT',
  MY_ORDERS = 'MY_ORDERS',
  // NOTIFICATION = "NOTIFICATION",
  // PAGE = "PAGE",
  RECENTLY_VIEWED = 'RECENTLY_VIEWED',
  SEARCH = 'SEARCH',
  SHOWCASE = 'SHOWCASE',
  WEB_URL = 'WEB_URL',
}

export enum IconType
{
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  EMPTY = 'EMPTY',
}

export enum VideoDisplayType
{
  FILL = 'FILL',
  FIT = 'FIT',
}

export enum MenuStyle
{
  LEFT_PANEL = 1,
  BOTTOM_BAR = 2,
}

export enum MenuSegments
{
  Fields = 'fields',
  Types = 'types',
  Settings = 'settings',
}

export enum MenuCustomFields
{
  COLLECTION = 'COLLECTION',
  PAGE = 'PAGE',
}

export class ComponentName
{
  static readonly [ComponentTypes.CAROUSEL] = $localize`:@@util-carousel:Carousel`;
  static readonly [ComponentTypes.CIRCLE] = $localize`:@@util-circle:Circle`;
  static readonly [ComponentTypes.COUNTDOWN_TIMER] = $localize`:@@util-countDownTimer:Countdown Timer`;
  static readonly [ComponentTypes.DISCOUNT] = $localize`:@@util-discount:Discount`;
  static readonly [ComponentTypes.DIVIDER] = $localize`:@@util-divider:Divider`;
  static readonly [ComponentTypes.GALLERY] = $localize`:@@util-gallery:Gallery`;
  static readonly [ComponentTypes.GRID] = $localize`:@@util-grid:Grid`;
  static readonly [ComponentTypes.IMAGE] = $localize`:@@util-image:Image`;
  static readonly [ComponentTypes.RECENTLY_VIEWED] = $localize`:@@util-recentlyViewed:Recently Viewed`;
  static readonly [ComponentTypes.SEARCH_BAR] = $localize`:@@util-searchBar:Search Bar`;
  static readonly [ComponentTypes.SLIDER] = $localize`:@@util-slider:Slider`;
  static readonly [ComponentTypes.SLIDESHOW] = $localize`:@@util-slideshow:Slide Show`;
  static readonly [ComponentTypes.TITLE] = $localize`:@@util-title:Title`;
  static readonly [ComponentTypes.VIDEO_PLAYER] = $localize`:@@util-videoPlayer:Video Player`;
}

export enum SlideType
{
  CAROUSEL = 'CAROUSEL',
  CIRCLE = 'CIRCLE',
  GALLERY = 'GALLERY',
  GRID = 'GRID',
  RECENTLY_VIEWED = 'RECENTLY_VIEWED',
  SLIDER = 'SLIDER',
  SLIDESHOW = 'SLIDESHOW',
}

export enum ProductStatus
{
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DRAFT = 'draft',
}

export const ExceptMenus = [
  // MenuType.CONTACT,
  MenuType.WEB_URL,
  // MenuType.MOBILE_APP_PRIVACY_POLICY,
  MenuCustomFields.COLLECTION,
  MenuCustomFields.PAGE,
];

export const MenuDefaultValue: IMenuItemDefault = {
  [MenuType.ALL_PRODUCT]: {
    name: $localize`:@@menuNames-allProducts:All Products`,
    icon: 'gift-outline',
    redirectTo: '/products',
  },
  [MenuType.CART]: {
    name: $localize`:@@menuNames-cart:Cart`,
    icon: 'bag-handle-outline',
    redirectTo: '/cart',
  },
  [MenuType.FAVORITES]: {
    name: $localize`:@@menuNames-favorites:Favorites`,
    icon: 'heart-outline',
    redirectTo: '/wishlist',
  },
  [MenuType.GROUP]: {
    name: $localize`:@@menuNames-group:Group`,
    icon: 'beer-outline',
    redirectTo: '/group',
  },
  [MenuType.HOME_PAGE]: {
    name: $localize`:@@menuNames-homePage:Home Page`,
    icon: 'home-outline',
    redirectTo: '/',
  },
  [MenuType.MY_ACCOUNT]: {
    name: $localize`:@@menuNames-myAccount:My Account`,
    icon: 'person-outline',
    redirectTo: '/account',
  },
  [MenuType.MY_ORDERS]: {
    name: $localize`:@@menuNames-myOrders:My Orders`,
    icon: 'cart-outline',
    redirectTo: '/orders',
  },
  // [MenuType.NOTIFICATION]: {
  //   name: $localize`:@@menuNames-notification:Notification`,
  //   icon: 'notifications-outline',
  //   redirectTo: "/notification"
  // },
  [MenuType.RECENTLY_VIEWED]: {
    name: $localize`:@@menuNames-recentlyViewed:Recently Viewed`,
    icon: 'eye-outline',
    redirectTo: '/recently-viewed',
  },
  [MenuType.SEARCH]: {
    name: $localize`:@@menuNames-search:Search`,
    icon: 'search-outline',
    redirectTo: '/products',
  },
  [MenuType.SHOWCASE]: {
    name: $localize`:@@menuNames-showcase:Showcase`,
    icon: 'grid-outline',
    redirectTo: '/showcase',
  },
  [MenuType.WEB_URL]: {
    name: $localize`:@@menuNames-webURL:Web URL`,
    icon: 'open-outline',
    redirectTo: '',
  },
  [MenuType.MOBILE_APP_PRIVACY_POLICY]: {
    name: $localize`:@@menuNames-privacySecurity:Privacy & Security`,
    icon: 'shield-outline',
    redirectTo: '/privacy',
  },
};

export const DEFAULT_IMG = Object.freeze({
  appIcon: 'assets/svg/app_icon_placeholder.svg',
  appSplashScreen: 'assets/svg/image_placeholder.svg',
  appTitleBanner: 'assets/svg/empty_title_bar.svg',
  cartIcon: 'assets/svg/cart/type_1.svg',
  commonImg: 'assets/svg/empty_img.svg',
  discount: 'assets/svg/discount_image.svg',
  menuIcon: 'assets/svg/menu/type_1.svg',
  recentlyView: 'assets/img/empty_eye.png',
});

export enum AdminProductSortKeys
{
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  INVENTORY_TOTAL = 'INVENTORY_TOTAL',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  PUBLISHED_AT = 'PUBLISHED_AT',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT',
  VENDOR = 'VENDOR',
}

export enum CollectionSortKeys
{
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT',
}

export enum UserProfileGender
{
  MALE = 1,
  FEMALE = 2,
}

export enum AppBuildStatus
{
  ready = 1,
  configured,
  running,
  success,
  failure,
  error,
  cancel,
  appReleaseRequired,
  appReleaseRunning,
  appReleaseSubmitted,
  appReleaseRejected
}

export enum AppPublishCategory
{
  Accounting = 'ACCOUNTING',
  Artificial_Intelligence = 'ARTIFICIAL_INTELLIGENCE',
  Business_Consulting = 'BUSINESS_CONSULTING',
  Business_Services = 'BUSINESS_SERVICES',
  Coaching = 'COACHING',
  Construction = 'CONSTRUCTION',
  Design = 'DESIGN',
  Digital_Engineering = 'DIGITAL_ENGINEERING',
  Digital_Marketing = 'DIGITAL_MARKETING',
  Financial_Services = 'FINANCIAL_SERVICES',
  Manufacturing = 'MANUFACTURING',
  Networking = 'NETWORKING',
  Real_Estate = 'REAL_ESTATE',
  Retail = 'RETAIL',
  Tools = 'TOOLS',
}

export enum APPBuildPlatform
{
  Android = 'Android',
  IOS = 'IOS',
}

export enum KEEP_ALIVE
{
  IDLE_COUNT = 5,
  TIMEOUT_COUNT = 3600,
  IDLE = "IDLE",
  NOT_IDLE = "NOT_IDLE",
  TIMED_OUT = "TIMED_OUT",
}

export enum A24SubscriptionStatus
{
  active = 1,
  canceled = 2,
  incomplete = 3,
  incomplete_expired = 4,
  past_due = 5,
  trialing = 6,
  unpaid = 7,
  unKnow = 8,
}
export enum PlanFrequencyType
{
  Monthly = 1,
  Yearly = 2,
  Daily = 3,
  Weekly = 4,
}
export class DATE_TIME_FORMAT
{
  static COMMON2_DATE_TIME_FORMAT = 'dd MMM YYYY hh:mm aa';
}
export enum InvoiceStatus
{
  Paid = 1,
  Pending = 2,
  Failed = 3,
}
export enum OrderStatus
{
  New = 1,
  Waiting_For_Payment = 8,
  Payment_Failed = 9,
  Fulfillment_Success = 10,
  Fulfillment_Failed = 11,
}

export class ROUTER_PARAM_PROPERTY
{
  static readonly ERP = 'erp';
  static readonly TRIAL = 'trial';
}
export enum PUBLIC_EMAIL_TYPE
{
  ENTERPRISE = 'WOV - Enterprise',
  ONBOARD = 'WOV - Onboard',
}
export enum PUBLIC_EMAIL_METHOD
{
  BUILD_NEW_PROJECT = 'Build a new project',
  SCALE_AN_EXISTING_PROJECT = 'Scale an existing project',
  OUTSOURCING = 'Outsourcing',
}

export enum PUBLIC_EMAIL_SERVICE
{
  DEVELOPMENT = 'Development',
  ONBOARD = 'Design',
  OTHER = 'Other',
  DEVELOPMENT_DESIGN = 'Development & Design',
}

export enum ZACE
{
  BASE_URL = 'https://api.zace.app/'
}