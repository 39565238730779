import { AppScreenSettings } from "types-magic-api/dist/src/index.model";
import { ActionTypes } from "src/app/util/constant";

export class SaveScreenAction
{
    static readonly type = ActionTypes.SaveScreen;

    constructor(
        public screenSettings: AppScreenSettings
    ) { }
}

export class ResetScreenAction
{
    static readonly type = ActionTypes.ResetScreen;
}
