import { Injectable, inject } from '@angular/core';
import { Params, Router } from '@angular/router';
import
  {
    AlertButton,
    AlertController,
    ModalController,
    NavController,
    ToastController,
  } from '@ionic/angular';
import { Store } from '@ngxs/store';
import clone from 'clone';
import
  {
    ICollection,
    IProduct,
    Media,
  } from 'types-magic-api/dist/src/index.model';
import { Subscription, lastValueFrom } from 'rxjs';
import { AlertModalComponent } from '../components/supportive-components/alert-modal/alert-modal.component';
import { CustomModalComponent } from '../components/supportive-components/custom-modal/custom-modal.component';
import { LoadingComponent } from '../components/supportive-components/loading/loading.component';
import { ClearStoreFunction } from '../core/store/clearStore';
import
  {
    AlertModal,
    AlertModalStatus,
    ErrorAlertVal,
    ToastTypes,
  } from '../model/alert.model';
import { APIResponse, Status } from '../model/api-response.model';
import { ColorsModel } from '../model/colors.model';
import { ComponentAttributeModel } from '../model/components.model';
import { FileUploadService } from '../services/file-upload/file-upload.service';
import { HeaderService } from '../services/header/header.service';
import { LoggerService } from '../services/logger/logger.service';
import { getUserDetails } from '../store/auth/auth.selectors';
import { ResetColorsAction } from '../store/colors/colors.action';
import { ResetUserMenusAction } from '../store/menu/menu.action';
import { ResetSettingsAction } from '../store/settings/settings.action';
import { VALIDATE_SUCCESS_RES } from './api-validate';
import
  {
    APP_ROUTES,
    AttributeTypes,
    ComponentTypes,
    DEFAULT_COLOR,
    ROUTER_PARAM,
  } from './constant';
import { ExceptionHandler } from './error-handler';
import { RegExps } from './regEx';
import { SHOPIFY_CONFIG } from './shopify-configs';
import { format } from 'date-fns';
import * as _ from "lodash";

@Injectable({ providedIn: 'root' })
export class UtilFunctions
{
  private _logger = inject(LoggerService);

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private alertController: AlertController,
    private store: Store,
    private router: Router,
    private headerService: HeaderService,
    private storeClear: ClearStoreFunction,
    private fileUploadService: FileUploadService
  ) { }

  @ExceptionHandler()
  public generateUniqueString(length = 5): string
  {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++)
    {
      const timestamp = Date.now().toString();
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result +=
        characters.charAt(randomIndex) + timestamp.charAt(timestamp.length - 1);
    }

    return result;
  }

  @ExceptionHandler()
  public async showToast(
    message: string,
    toastType = ToastTypes.Success,
    duration = 2000
  ): Promise<void>
  {
    const toast = await new ToastController().create({
      color: toastType,
      cssClass: 'toast-custom-class',
      duration,
      message,
      position: 'bottom',
    });

    await toast.present();
  }

  @ExceptionHandler()
  public twoDecimalsNumber(value: number): number
  {
    return parseFloat(value.toFixed(2));
  }

  @ExceptionHandler()
  public unsubscribeAll(subscriptions: Subscription[])
  {
    subscriptions.forEach((subscription) =>
    {
      subscription.unsubscribe();
    });
  }

  @ExceptionHandler()
  public cloneDeep<T>(value: T): T
  {
    const clonedValue = clone(value);
    return clonedValue;
  }

  @ExceptionHandler()
  public stimulateClick(fileId: string)
  {
    const inputElement = document.getElementById(fileId);

    if (!inputElement)
    {
      throw new Error(`The element with the ID "${ fileId }" was not found.`);
    }

    (inputElement as HTMLInputElement).click();
  }

  @ExceptionHandler()
  public async closeModal<T>(data?: T)
  {
    const modal = await this.modalController.getTop();

    if (!modal)
    {
      return;
    }

    await modal.dismiss(data);
  }

  @ExceptionHandler()
  public async navigate(
    url: string | any[],
    replaceUrl?: boolean | undefined,
    queryParams?: Params | null | undefined,
    state?: { [k: string]: any; } | undefined
  )
  {
    await this.navController.navigateRoot([url], {
      queryParams,
      state,
      replaceUrl,
    });
  }

  @ExceptionHandler()
  public async openAlertModal(
    header: string,
    message: string,
    buttons: AlertButton[]
  )
  {
    const alert = await this.alertController.create({
      cssClass: 'custom_alert_modal',
      header,
      message,
      buttons,
    });

    await alert.present();
  }

  @ExceptionHandler()
  public createLogRequest(fileName: string, functionName: string, error: any)
  {
    let errorObj = 'No error object is passed';

    if (error)
    {
      if (typeof error === 'string')
      {
        errorObj = error;
      } else if (error.message)
      {
        if (typeof error.message === 'string')
        {
          errorObj = error.message;
        } else
        {
          errorObj = JSON.parse(JSON.stringify(error.message));
        }
      } else
      {
        errorObj = JSON.parse(JSON.stringify(error));
      }
    }

    const returnValue = this._logger.logError(
      `${ errorObj } ${ error }`,
      fileName,
      functionName
    );

    return returnValue;
  }

  @ExceptionHandler()
  private handleJWT(pError: any, pDoNotNav = false)
  {
    let retValue: boolean = false;

    if (pError)
    {
      let queryParams = {} as any;
      let isJwt = false;

      if (!pError.error) return retValue;

      for (let index = 0; index < pError.error.length; index++)
      {
        const err = pError.error[index];
        if (
          err.code == Status.ERROR_JWT_01 ||
          err.code == Status.ERROR_JWT_02 ||
          err.code == Status.ERROR_AUT2 ||
          err.code == Status.ERROR_PER
        )
        {
          queryParams[ROUTER_PARAM.PARAM] = [err.code, err.value];
          isJwt = true;
        }
      }

      if (!isJwt) return retValue;

      retValue = true;

      if (!pDoNotNav)
      {
        if (queryParams.param.length > 0)
        {
          this.store
            .selectOnce(getUserDetails())
            .subscribe(async (loginResponse) =>
            {
              if (loginResponse)
              {
                await this.navToLogin(queryParams);
              }
            });
        } else
        {
          retValue = false;
        }
      }
    }

    return retValue;
  }

  @ExceptionHandler()
  private async navToLogin(queryParams: { param: string[]; })
  {
    this.storeClear.toClearStore(true);
    this.router.navigate([APP_ROUTES.LOGIN], {
      queryParams: { code: queryParams.param[0], error: queryParams.param[1] },
      replaceUrl: true,
      state: { allow: true },
    });
    setTimeout(() =>
    {
      this.closeModal();
    }, 1000);
    // this.navigate([APP_ROUTES.LOGIN], true,
    //   {
    //     code: queryParams.param[0],
    //     error: queryParams.param[1]
    //   },
    //   {
    //     allow: true
    //   });
  }

  @ExceptionHandler()
  public getErrorAlertValue(pError: any): ErrorAlertVal
  {
    const errorAlert: ErrorAlertVal = {
      codeList: [],
      valueList: [],
    };

    if (pError && pError.error && pError.error.length)
    {
      for (let index = 0; index < pError.error.length; index++)
      {
        if (pError.error[index].code)
        {
          errorAlert.codeList.push(pError.error[index].code);
        }
        if (pError.error[index].value)
        {
          errorAlert.valueList.push(pError.error[index].value);
        }
      }

      this._logger.logError(
        JSON.stringify(pError),
        'utils.ts',
        'getErrorAlertValue'
      );
    } else
    {
      errorAlert.valueList = ['Failed'];
    }

    return errorAlert;
  }

  @ExceptionHandler()
  public async handleErrorRes<T>(
    response: APIResponse<T>,
    callBackFunc?: Function,
    removeErrCode = false
  )
  {
    if (response.status === Status.Error)
    {
      const isJwt = this.handleJWT(response);
      if (!isJwt)
      {
        const modalProp: AlertModal = {
          buttonName: [$localize`:@@common.okBtn:OK`],
          desc: $localize`:@@common-try_again:Please try again later`,
          status: AlertModalStatus.failed,
          title: AlertModalStatus.failed,
        };
        modalProp.desc = this.getErrorAlertValue(response);
        await this.showErrorAlertModal(modalProp, callBackFunc, removeErrCode);
      }
    }
  }

  @ExceptionHandler()
  public async showErrorAlertModal(
    modalProps: AlertModal,
    callBackFunc: Function | undefined,
    removeErrCode = false
  )
  {
    const modal = await this.modalController.create({
      component: CustomModalComponent,
      id: modalProps.status + '_alert-modal',
      componentProps: {
        data: modalProps,
        removeErrCode,
      },
    });

    await modal.present();

    modal.onDidDismiss().then((modelData) =>
    {
      if (!callBackFunc)
      {
        return;
      }

      callBackFunc(modelData.data);
    });
  }

  @ExceptionHandler()
  public async showAlertModal(
    modalProps: AlertModal,
    callBackFunc?: Function,
    backdropDismiss = true,
    isInnerHtml?: boolean,
    changeBtnColor?: boolean
  )
  {
    const modal = await this.modalController.create({
      component: AlertModalComponent,
      id: modalProps.status + '_alert-modal',
      backdropDismiss,
      componentProps: {
        data: modalProps,
        isInnerHtml: isInnerHtml,
        onDialogClose: callBackFunc,
        deleteBtn: changeBtnColor,
      },
    });

    await modal.present();

    modal.onDidDismiss().then((modelData) =>
    {
      if (!callBackFunc || !modelData.data)
      {
        return;
      }

      callBackFunc(modelData.data);
    });
  }

  @ExceptionHandler()
  public JSONClone<T>(originalVal: T): T
  {
    const cloned: T = JSON.parse(JSON.stringify(originalVal));

    return cloned;
  }

  @ExceptionHandler()
  public isObjectEmpty(object: Object): boolean
  {
    return Object.keys(object).length === 0;
  }

  @ExceptionHandler()
  async clearChanges(_isCanceled: boolean)
  {
    if (_isCanceled)
    {
      await lastValueFrom(
        this.store.dispatch([
          new ResetUserMenusAction(),
          new ResetColorsAction(),
          new ResetSettingsAction(),
        ])
      );
      this.headerService.emitOnClear(_isCanceled);
    }
  }

  @ExceptionHandler()
  getClassNamesForStyling(pList: ComponentAttributeModel[])
  {
    let retValue: string = '';

    retValue =
      pList
        .filter((attr) => attr.type == AttributeTypes.BOOLEAN)
        .map((item) => item.name)
        .join(' ') +
      ' ' +
      pList
        .filter((attr) => attr.type == AttributeTypes.ENUM)
        .map((item) => item.retValue)
        .join(' ');

    retValue = retValue
      .replace(/\.?([A-Z])/g, function (x, y)
      {
        return '_' + y.toLowerCase();
      })
      .replace(/^_/, '');

    return retValue.trim() ? retValue : '';
  }

  @ExceptionHandler()
  handleErrorStatus(
    fileName: string,
    functionName: string,
    errorMessage: string
  )
  {
    this._logger.logError(fileName, functionName, errorMessage);
  }

  @ExceptionHandler()
  getFormattedId(id: string | number)
  {
    return (id as string).replace(RegExps.shopifyRegex, '');
  }

  @ExceptionHandler()
  checkIsSlideComp(contentType: string)
  {
    return [
      ComponentTypes.CAROUSEL,
      ComponentTypes.CIRCLE,
      ComponentTypes.GALLERY,
      ComponentTypes.GRID,
      ComponentTypes.RECENTLY_VIEWED,
      ComponentTypes.SLIDER,
      ComponentTypes.IMAGE,
      ComponentTypes.SLIDESHOW,
    ].includes(contentType as ComponentTypes);
  }

  @ExceptionHandler()
  public isProductComp(contentType: string)
  {
    return [
      ComponentTypes.CAROUSEL,
      ComponentTypes.CIRCLE,
      ComponentTypes.GALLERY,
      ComponentTypes.GRID,
      ComponentTypes.IMAGE,
      ComponentTypes.SLIDER,
      ComponentTypes.SLIDESHOW,
      ComponentTypes.SLIDESHOW,
    ].includes(contentType as ComponentTypes);
  }

  @ExceptionHandler()
  getProperIds(ids: string[], isProducts: boolean): string[]
  {
    const baseUrl = isProducts
      ? SHOPIFY_CONFIG.PRODUCT_BASE_URL
      : SHOPIFY_CONFIG.COLLECTION_BASE_URL;

    return ids.map((id) => (id.startsWith(baseUrl) ? id : baseUrl + id));
  }

  @ExceptionHandler()
  removeIdPrefix(id: string, isProducts: boolean): string
  {
    const query = isProducts
      ? SHOPIFY_CONFIG.PRODUCT_BASE_URL
      : SHOPIFY_CONFIG.COLLECTION_BASE_URL;

    return id.replace(query, '');
  }

  @ExceptionHandler()
  public get24hFromNow()
  {
    const now = new Date().getTime();
    const oneDay = 86400000;

    return new Date(now + oneDay).toISOString();
  }

  @ExceptionHandler()
  public async uploadMedia(file: File): Promise<Media | null>
  {
    this.showLoadingModal();

    const formData = this.getFormData(file);
    console.error(formData.get('file'));
    const res = await this.fileUploadAPIResHandler(formData);

    this.closeModal();

    return res;
  }

  @ExceptionHandler()
  private getFormData(file: File)
  {
    const formData = new FormData();

    formData.append('file', file);

    return formData;
  }

  @ExceptionHandler()
  public debounce(func: Function, timeout = 500)
  {
    let timer!: number;

    return <T>(...args: T[]) =>
    {
      clearTimeout(timer);
      timer = setTimeout(() =>
      {
        func.apply(this, args);
      }, timeout);
    };
  }

  @ExceptionHandler()
  private async fileUploadAPIResHandler(
    formData: FormData
  ): Promise<Media | null>
  {
    let media: Media | null = null;

    try
    {
      const res = await this.fileUploadService.uploadFile(formData);

      if (VALIDATE_SUCCESS_RES(res))
      {
        media = res.data;
      } else
      {
        this.handleErrorRes(res);
      }
    } catch (error)
    {
      const toastMsg = $localize`:@@common-errorTryAgain:Something went wrong, Please try again.`;
      this.showToast(toastMsg, ToastTypes.Failure);
    }

    return media;
  }

  @ExceptionHandler()
  public generateRandomDiscountString(length: number)
  {
    const characters = '0123456789ABCDEF';
    const charactersLength = characters.length;

    let randomString = '';

    for (let i = 0; i < length; i++)
    {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  }

  @ExceptionHandler()
  public openExternalLink(link: string, target = '_blank')
  {
    window.open(link, target);
  }

  @ExceptionHandler()
  public getProductPrice(item: IProduct | ICollection):
    {
      price: string | null;
      comparePrice: string | null;
    }
  {
    let price = null;
    let comparePrice = null;

    if ('priceRange' in item)
    {
      const { amount, currencyCode } = (item as IProduct).priceRange
        .minVariantPrice;

      price = `${ currencyCode } ${ amount }`;

      if (
        !Number.isNaN(Number(item.comparePrice)) &&
        Number(item.comparePrice) !== 0
      )
      {
        comparePrice = `${ currencyCode } ${ item.comparePrice }`;
      }
    }

    return { price, comparePrice };
  }

  @ExceptionHandler()
  private async showLoadingModal()
  {
    const modal = await this.modalController.create({
      component: LoadingComponent,
      cssClass: 'loading_modal',
      componentProps: {
        needLabel: true,
      },
      backdropDismiss: false,
    });

    await modal.present();
  }

  @ExceptionHandler()
  public updateCssAppColorVariables(colors: ColorsModel | null)
  {
    if (!colors)
    {
      return;
    }

    const html = document.getElementById('app-html') as HTMLElement;

    if (!html)
    {
      return;
    }

    const { primaryColor, secondaryColor, cartColor } = colors;

    html.style.setProperty(
      '--app-primary-color',
      this.isHexColor(primaryColor) || DEFAULT_COLOR.primaryColor
    );
    html.style.setProperty(
      '--app-secondary-color',
      this.isHexColor(secondaryColor) || DEFAULT_COLOR.secondaryColor
    );
    html.style.setProperty(
      '--app-cart-color',
      this.isHexColor(cartColor) || DEFAULT_COLOR.cartColor
    );
  }

  @ExceptionHandler()
  public updateCssMenuColorVariables(menuColor: string | null)
  {
    if (!menuColor)
    {
      return;
    }

    const html = document.getElementById('app-html') as HTMLElement;

    if (!html)
    {
      return;
    }

    html.style.setProperty(
      '--app-menu-color',
      this.isHexColor(menuColor) || DEFAULT_COLOR.menuColor
    );
    html.style.setProperty(
      '--menu-color',
      this.isHexColor(menuColor) || DEFAULT_COLOR.menuColor
    );
  }

  @ExceptionHandler()
  public isHexColor(color: string): string | null
  {
    const hexReg = /^#([0-9a-f]{3}){1,2}$/i;

    const isValid = hexReg.test(color);
    return isValid ? color : null;
  }

  @ExceptionHandler()
  public formatDate(date: string | Date, formatStr: string)
  {
    return format(new Date(date), formatStr);
  }

  //List of obj - to group by object property
  @ExceptionHandler()
  groupBy(pList: any, pGroupBy: string)
  {
    let retValue;
    retValue = _.groupBy(pList, x => x[pGroupBy]);
    return retValue;
  }
}
