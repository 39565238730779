import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom, map } from 'rxjs';
import { APIResponse } from 'src/app/model/api-response.model';
import { BASE_CONFIG } from 'src/app/util/base-configs';
import { ExceptionHandler } from 'src/app/util/error-handler';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private _logger = inject(LoggerService);

  dataSubject = new BehaviorSubject<boolean | null>(null);

  private BASE_URL: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  /**
   * This function makes an HTTP POST request with optional request and response types and returns an
   * observable of the API response.
   * @param {string} serviceURL - The URL of the API service that the HTTP POST request will be sent
   * to.
   * @param {Request | null} requestBody - The data that needs to be sent in the HTTP POST request. It
   * can be of type Request or null.
   * @param [isBlobResponseType=false] - A boolean flag indicating whether the expected response type is a
   * blob or not. If set to true, the response type will be a blob, otherwise it will be a JSON object.
   * @returns An Observable of type APIResponse<Response> is being returned.
   */
  @ExceptionHandler()
  public makeHttpPostRequest<Request, Response>(
    serviceURL: string,
    requestBody: Request | null,
    isBlobRequestType = false,
    isBlobResponseType = false,
    pBaseUrl = ''
  ): Observable<APIResponse<Response>> {
    this.dataSubject.next(true);

    const headers = isBlobRequestType
      ? this.getBlobHttpHeader()
      : this.getHttpHeader();

    const url = (pBaseUrl ? pBaseUrl : this.BASE_URL) + serviceURL;

    const response: any = isBlobResponseType
      ? this.blobCall(url, requestBody, headers)
      : this.jsonCall(url, requestBody, headers);

    return response;
  }

  @ExceptionHandler()
  public makeHttpPromisePostRequest<Request, Response>(
    serviceURL: string,
    requestBody: Request | null,
    isBlobRequestType = false,
    isBlobResponseType = false
  ): Promise<APIResponse<Response>> {
    this.dataSubject.next(true);

    const headers = isBlobRequestType
      ? this.getBlobHttpHeader()
      : this.getHttpHeader();

    const url = this.BASE_URL + serviceURL;

    const response: any = isBlobResponseType
      ? this.blobPromiseCall(url, requestBody, headers)
      : this.jsonPromiseCall(url, requestBody, headers);

    return response;
  }

  @ExceptionHandler()
  private getHttpHeader(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': BASE_CONFIG.LANGUAGE,
    });
  }

  @ExceptionHandler()
  private getBlobHttpHeader(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Accept-Language': BASE_CONFIG.LANGUAGE,
    });
  }

  @ExceptionHandler()
  private jsonCall<Request>(
    url: string,
    requestBody: Request | null,
    headers: HttpHeaders
  ): Observable<Object> {
    return this.httpClient
      .post(url, requestBody, {
        headers,
        responseType: 'json',
      })
      .pipe(
        map((response) => {
          this.dataSubject.next(false);

          return response;
        })
      );
  }

  @ExceptionHandler()
  private blobCall<Request>(
    url: string,
    requestBody: Request | null,
    headers: HttpHeaders
  ): Observable<Blob> {
    return this.httpClient
      .post(url, requestBody, {
        headers,
        responseType: 'blob',
      })
      .pipe(
        map((response) => {
          this.dataSubject.next(false);

          return response;
        })
      );
  }

  @ExceptionHandler()
  private async jsonPromiseCall<Request>(
    url: string,
    requestBody: Request | null,
    headers: HttpHeaders
  ): Promise<Object> {
    const retValue = await lastValueFrom(
      this.httpClient.post(url, requestBody, {
        headers,
        responseType: 'json',
      })
    );

    this.dataSubject.next(false);

    return retValue;
  }

  @ExceptionHandler()
  private async blobPromiseCall<Request>(
    url: string,
    requestBody: Request | null,
    headers: HttpHeaders
  ): Promise<Blob> {
    const retValue = await lastValueFrom(
      this.httpClient.post(url, requestBody, {
        headers,
        responseType: 'blob',
      })
    );

    this.dataSubject.next(false);

    return retValue;
  }
}
