import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomModalComponent } from './custom-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [CustomModalComponent],
  exports: [CustomModalComponent]
})
export class CustomModalModule { }
