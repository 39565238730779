import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent
{

  @Input() needLabel = false;

  protected readonly gradientColors = [
    "#0033cc", "#0040ff", "#3366ff",
    "#4d88ff", "#4d88ff", "#4d88ff",
    "#3366ff", "#0040ff", "#0033cc"
  ];
}
