import { createSelector } from "@ngxs/store";
import { CommonActionModal } from "src/app/model/common.modal";
import { CommonState } from "./common.state";


export const isIntroVisited = () =>
  createSelector([CommonState], (state: CommonActionModal) => state.isIntroVisited);

export const isTourVisited = () =>
  createSelector([CommonState], (state: CommonActionModal) => state.isTourVisited);

export const isNewUser = () =>
  createSelector([CommonState], (state: CommonActionModal) => state.isNewUser);


export const getAllProviders = () =>
  createSelector([CommonState], (state: CommonActionModal) => state.providers);


export const getAppVersion = () =>
  createSelector([CommonState], (state: CommonActionModal) => state.version);

export const getFileUpload = () =>
  createSelector([CommonState], (state: CommonActionModal) => state.fileUpload);