import { Component, Input, OnInit, inject } from '@angular/core';
import {
  AlertModal,
  AlertModalStatus,
  ModalClass,
  ModalIconClass,
} from 'src/app/model/alert.model';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ExceptionHandler } from 'src/app/util/error-handler';
import { UtilFunctions } from 'src/app/util/utils';

@Component({
  selector: 'app-custom-modal-component',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModalComponent implements OnInit {
  private _logger = inject(LoggerService);

  readonly MODAL_STATUS = AlertModalStatus;

  protected readonly ModalStatusClass: ModalClass = {
    [AlertModalStatus.success]: 'popup-success',
    [AlertModalStatus.failed]: 'popup-fail',
    [AlertModalStatus.confirm]: 'popup-confirm',
  };

  protected readonly ModalButtonClass: ModalClass = {
    [AlertModalStatus.success]: 'popup-button-success',
    [AlertModalStatus.failed]: 'popup-button-failure',
    [AlertModalStatus.confirm]: 'popup-button-confirm',
  };

  protected ModalIconClass!: ModalIconClass;
  protected isString = false;

  @Input() data: AlertModal | undefined;
  @Input() className: string | undefined;
  @Input() removeErrCode = false;
  emitFunc: any;

  constructor(private util: UtilFunctions) {}

  @ExceptionHandler()
  ngOnInit() {
    this.ModalIconClass = {
      [AlertModalStatus.success]: {
        class: 'success-img icon_sec',
        iconName: 'checkmark-circle-outline',
        iconClass: '',
      },
      [AlertModalStatus.failed]: {
        class: 'failure-img icon_sec',
        iconName: 'alert-circle-outline',
        iconClass: 'failure-status',
      },
      [AlertModalStatus.confirm]: {
        class: 'confirm-img icon_sec',
        iconName: 'alert-circle-outline',
        iconClass: '',
      },
    };

    this.isString = typeof this.data?.desc === 'string';
  }

  @ExceptionHandler()
  async onClose(isConfirm = false): Promise<void> {
    if (this.emitFunc) {
      this.emitFunc();
    } else {
      this.util.closeModal({ isConfirm, status: this.data?.status });
    }
  }
}
