import { Component, inject } from '@angular/core';
import
  {
    AlertModal,
    AlertModalStatus,
    ModalClass,
    ModalIconClass,
  } from 'src/app/model/alert.model';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ExceptionHandler } from 'src/app/util/error-handler';
import { UtilFunctions } from 'src/app/util/utils';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})

export class AlertModalComponent
{

  private _logger = inject(LoggerService);

  readonly MODAL_STATUS = AlertModalStatus;

  readonly ModalStatusClass: ModalClass = {
    [AlertModalStatus.success]: 'popup-success',
    [AlertModalStatus.failed]: 'popup-fail',
    [AlertModalStatus.confirm]: 'popup-conform',
  };

  readonly ModalButtonClass: ModalClass = {
    [AlertModalStatus.success]: 'popup-button-success',
    [AlertModalStatus.failed]: 'popup-button-failure',
    [AlertModalStatus.confirm]: 'popup-button-conform transparent_btn',
  };

  readonly ModalIconClass: ModalIconClass = {
    [AlertModalStatus.success]: {
      class: 'success-img icon_sec',
      iconName: 'checkmark-circle',
      iconClass: '',
    },
    [AlertModalStatus.failed]: {
      class: 'failure-img icon_sec',
      iconName: 'close-circle',
      iconClass: 'failure-status',
    },
    [AlertModalStatus.confirm]: {
      class: 'confirm-img icon_sec',
      iconName: 'alert-circle',
      iconClass: '',
    },
  };

  protected className: string | undefined;
  protected data: AlertModal | undefined;
  protected isInnerHtml = false;
  protected onDialogClose: any;
  protected deleteBtn = false;

  constructor(private util: UtilFunctions) { }

  @ExceptionHandler()
  protected async modalClickHandler(isConfirm = false)
  {
    this.onDialogClose(isConfirm);

    await this.util.closeModal();
  }
}
