import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { Language } from "types-magic-api/dist/src/index.model";
import { ResetLanguageAction, SaveLanguageAction } from "./language.action";


const SETTINGS = new StateToken<Language[]>('Language');

@State({
  name: SETTINGS,
  defaults: null
})

@Injectable()
export class LanguageState 
{
  @Action(SaveLanguageAction)
  saveLanguage(
    ctx: StateContext<Language[]>,
    action: SaveLanguageAction
  )
  {
    ctx.setState(action.languages);
  }

  @Action(ResetLanguageAction)
  resetLanguage(
    ctx: StateContext<null>
  )
  {
    ctx.setState(null);
  }
}
