export type ModalClass = {
  [key in AlertModalStatus]: string
};


export type ModalIconClass = {
  [key in AlertModalStatus]: {
    class: string,
    iconName: string;
    iconClass: string;
  }
};


interface CustomModalIcon
{
  class: string,
  iconSrc: string;
}


export interface AlertModal
{
  buttonName: string | string[],
  desc: string | ErrorAlertVal,
  status: AlertModalStatus,
  title: string;
  icon?: string;
}


export enum AlertModalStatus
{
  success = "Success",
  failed = "Failed",
  confirm = 'Confirmation'
}


export interface ErrorAlertVal
{
  codeList: string[];
  valueList: string[];
}


export enum ToastTypes
{
  Success = "success",
  Warning = "warning",
  Failure = "danger"
}