import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { SERVICE_URL } from 'src/app/util/constant';
import { GetProvidersRes } from 'types-magic-api/dist/src/index.model';

@Injectable({
  providedIn: 'root'
})
export class ProviderService
{

  constructor(
    private http: HttpService
  ) { }


  public getAllProviders()
  {
    return this.http.makeHttpPostRequest<null, GetProvidersRes>(
      SERVICE_URL.GET_ALL_PROVIDER,
      null
    );
  }

}
