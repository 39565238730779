import { Language } from "types-magic-api/dist/src/index.model";
import { ActionTypes } from "src/app/util/constant";

export class SaveLanguageAction
{
    static readonly type = ActionTypes.SaveLanguage;

    constructor(
        public languages: Language[]
    ) { }
}

export class ResetLanguageAction
{
    static readonly type = ActionTypes.ResetLanguage;
}
