import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProvidersModalComponent } from './providers-modal.component';
import { DropdownModule } from '../dropdown/dropdown.module';



@NgModule({
  declarations: [ProvidersModalComponent],
  exports: [ProvidersModalComponent],
  imports: [
    SharedModule,
    DropdownModule
  ]
})
export class ProvidersModalModule { }
