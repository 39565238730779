import { Injectable, inject } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngxs/store";
import { Provider } from "types-magic-api/dist/src/index.model";
import { ProvidersModalComponent } from "../components/supportive-components/providers-modal/providers-modal.component";
import { LoggerService } from "../services/logger/logger.service";
import { ProviderService } from "../services/provider/provider.service";
import { SaveProvidersAction } from "../store/common/common.action";
import { getAllProviders } from "../store/common/common.selector";
import { VALIDATE_SUCCESS_RES } from "./api-validate";
import { ExceptionHandler } from "./error-handler";
import { UtilFunctions } from "./utils";


@Injectable({ providedIn: "root" })
export class AppProviders
{
  private _logger = inject(LoggerService);


  constructor(
    private modalController: ModalController,
    private providerService: ProviderService,
    private store: Store,
    private util: UtilFunctions,
  ) { }


  @ExceptionHandler()
  public setProvidersInStore()
  {
    this.getAppProviders();
  }


  @ExceptionHandler()
  public openAppProviderModel()
  {
    this.store.selectOnce(getAllProviders())
      .subscribe(async _providers =>
      {
        if (!_providers?.length)
        {
          this.getAppProviders(true);
          return;
        }

        await this.showProvidersModal(_providers);
      });
  }


  @ExceptionHandler()
  private getAppProviders(
    showModal = false
  )
  {
    this.providerService.getAllProviders()
      .subscribe({
        next: async (res) =>
        {
          if (VALIDATE_SUCCESS_RES(res))
          {
            const providers = this.util.cloneDeep(res.data.provider);

            this.store.dispatch(new SaveProvidersAction(providers));

            if (showModal) { await this.showProvidersModal(providers); }
          }
          else
          {
            this.util.handleErrorRes(res);
          }
        }
      });
  }


  @ExceptionHandler()
  private async showProvidersModal(
    providers: Provider[]
  )
  {
    const modal = await this.modalController.create({
      component: ProvidersModalComponent,
      id: 'provider_modal',
      componentProps: {
        providers
      },
      backdropDismiss: false
    });

    await modal.present();
  };
}