import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerRequest, LOG_TYPE } from 'src/app/model/logger.modal';
import { BASE_CONFIG } from 'src/app/util/base-configs';


@Injectable({
  providedIn: 'root'
})
export class LoggerService
{
  constructor(
    private route: Router,
    private http: HttpClient
  ) { }

  public logError(
    errMessage: string,
    fileName: string,
    funcName: string,
  ): void
  {
    try
    {
      const logRequest = this.buildRequest(
        fileName,
        funcName,
        errMessage
      );

      this.storeLog(logRequest);
    }
    catch (error)
    {
      const errMessage = (error as any).toString();

      this.logError(
        errMessage,
        'logger.service.ts',
        "logError",
      );
    }
  }


  private buildRequest(
    fileName: string,
    funcName: string,
    errMessage: string
  ): LoggerRequest
  {
    let retValue!: LoggerRequest;
    try
    {
      const errTitle = `error in ${ funcName }`;

      retValue = {
        appId: BASE_CONFIG.LOG_APP_ID,
        browser: BASE_CONFIG.BROWSER,
        browserversion: "",
        errtitle: errTitle,
        functionname: funcName,
        level: LOG_TYPE.ERROR,
        message: errMessage,
        module: fileName,
        ostype: BASE_CONFIG.PLATFORM,
        pathName: this.route.url,
        timestamp: new Date().toISOString(),
        versioncode: BASE_CONFIG.VERSION,
      };
    }
    catch (error)
    {
      const errMessage = (error as any).toString();

      this.logError(
        errMessage,
        'logger.service.ts',
        "buildRequest",
      );
    }
    return retValue;
  }

  private storeLog(
    logRequest: LoggerRequest
  ): void
  {
    try
    {
      console.error(logRequest);
      // this.http.post(
      //   BASE_CONFIG.LOGGER_URL,
      //   logRequest
      // ).subscribe();

    }
    catch (error)
    {
      const errMessage = (error as any).toString();

      this.logError(
        errMessage,
        'logger.service.ts',
        "storeLog",
      );
    }
  }

}
