import { Injectable } from "@angular/core";
import { AuthAdminTokenAction } from "./auth-admin.actions";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { AuthAdminModel } from "src/app/model/auth-admin.model";

const initialAuthAdminState: AuthAdminModel = {
    token: null
};

export const STATE_TOKEN = new StateToken<AuthAdminModel>('AuthAdmin');

@State({
    name: STATE_TOKEN,
    defaults: initialAuthAdminState,
})

@Injectable()
export class AuthAdminState
{
    constructor() { }

    @Action(AuthAdminTokenAction)
    setUserDetails(
        data: StateContext<AuthAdminModel>,
        action: AuthAdminTokenAction
    ) 
    {
        const token = action.authToken;

        data.setState({
            token
        });
    }
}