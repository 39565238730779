export const RegExps = {
  alphaNumeric: /(^[A-Za-z0-9]+$)/g,
  alphaNumericWithSpaces: /^[a-zA-Z0-9 ]*$/gm,
  alphaWithSpace: /(^[A-Za-z_ ]+$)/g,
  email: /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  // mobile: /^([0-9\(\)\/\+ \-]*)$/g,
  mobile: /^[0-9]{0,14}$/g,
  notAllowNumerics: /^([^0-9]*)$/g,
  notAllowSpaces: /^([A-z0-9!@#$%^&*().,<>{}[\]<>?_=+\-|;:\'\"\/])*[^\s]\1*$/,
  numberWithDecimal: /^\d+(\.\d+)?$/,
  numberWithSpace: /^[0-9\s]*$/,
  numberWithTwoDecimal: /^\d+(\.\d{0,2})?$/,
  onlyAlpha: /(^[A-Za-z]+$)/g,
  onlyNumber: /(^[0-9]+$)/g,
  password: /^((?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{8,20}).*$/,
  replaceCommas: /[,]/g,
  size: /^[0-9 x.]+$/,
  userName: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/,
  validAmount: /^([0-9,]{1,19})([.][0-9]{1,2})*$/,
  shopifyRegex: /gid:\/\/shopify\/(Product|Collection)\//
};