import { APIResponse, Status } from "../model/api-response.model";

export function VALIDATE_SUCCESS_RES<T>(
  response: APIResponse<T>
): boolean
{
  let isValid = false;

  if (
    response &&
    response.data &&
    response.status === Status.Success
  )
  {
    isValid = true;
  }

  return isValid;
}


export function getErrorMessage<T>(
  response: APIResponse<T>
): any
{
  let isValid = null;

  if (
    response &&
    response.status === Status.Error &&
    response.error &&
    response.error.length > 0
  )
  {
    isValid = response.error;
  }

  return isValid;
}
