import { Injectable, inject } from "@angular/core";
import { Store } from "@ngxs/store";
import { lastValueFrom } from 'rxjs';
import { LoggerService } from "src/app/services/logger/logger.service";
import { AuthAdminTokenAction } from "src/app/store/auth-admin/auth-admin.actions";
import { AuthTokenAction, ProviderTokenAction, UserDetailAction } from "src/app/store/auth/auth.actions";
import { ResetColorsAction } from "src/app/store/colors/colors.action";
import { ResetCommonAction } from "src/app/store/common/common.action";
import { ResetLanguageAction } from "src/app/store/language/language.action";
import { ResetUserMenusAction } from "src/app/store/menu/menu.action";
import { ResetScreenAction } from "src/app/store/screens/screens.action";
import { ResetSettingsAction } from "src/app/store/settings/settings.action";
import { StoreUserComponentsAction } from "src/app/store/showcase/showcase.action";
import { ExceptionHandler } from "src/app/util/error-handler";

@Injectable({
  providedIn: "root"
})

export class ClearStoreFunction
{
  private _logger = inject(LoggerService);

  constructor(
    private store: Store
  ) { }

  @ExceptionHandler()
  async toClearStore(
    isClearProviderToken = false
  )
  {
    const storesToClear = [
      new ResetColorsAction(),
      new ResetLanguageAction(),
      new ResetScreenAction(),
      new ResetSettingsAction(),
      new ResetUserMenusAction(),
      new StoreUserComponentsAction([]),
      new UserDetailAction(null),
    ];

    if (isClearProviderToken)
    {
      storesToClear.push(
        new AuthTokenAction(null),
        new ProviderTokenAction(null),
        new ResetCommonAction(),
        new AuthAdminTokenAction(null),
      );
    }

    await lastValueFrom(this.store.dispatch(storesToClear));
  }

  @ExceptionHandler()
  forceClearLocalStorage()
  {
    this.toClearStore();
  }

  @ExceptionHandler()
  clearOnForceAction()
  {
    localStorage.clear();
  }
}