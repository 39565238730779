import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from './util/constant';

const routes: Routes = [
  {
    path: APP_ROUTES.HOME,
    redirectTo: APP_ROUTES.LANDING,
    pathMatch: 'full',
  },
  {
    path: APP_ROUTES.HOME,
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: APP_ROUTES.AUTH,
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: APP_ROUTES.LANDING,
    loadChildren: () =>
      import('./pages/landing/landing.module').then((m) => m.LandingPageModule),
  },
  {
    path: APP_ROUTES.UNSUBSCRIBE,
    loadChildren: () =>
      import('./pages/unsubscribe/unsubscribe.module').then(
        (m) => m.UnsubscribePageModule
      ),
  },
  {
    path: APP_ROUTES.DELETE,
    loadChildren: () => import('./pages/delete/delete.module').then(m => m.DeletePageModule)
  },
  {
    path: APP_ROUTES.ADMIN,
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminPageModule),
  },
  {
    path: APP_ROUTES.GUIDE,
    loadChildren: () => import('./pages/guide/guide.module').then(m => m.GuidePageModule)
  },
  {
    path: APP_ROUTES.ONBOARD,
    loadChildren: () => import('./pages/onboard/onboard.module').then((m) => m.OnboardPageModule),
  },
  {
    path: '**',
    redirectTo: APP_ROUTES.LANDING,
    pathMatch: 'full',
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
