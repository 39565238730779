import { Component, ContentChild, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, inject } from '@angular/core';
import { DebounceQuery } from 'src/app/model/common.modal';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ExceptionHandler } from 'src/app/util/error-handler';
import { UtilFunctions } from 'src/app/util/utils';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent<T, S> implements OnInit
{
  private _logger = inject(LoggerService);

  protected readonly notFoundText: string = $localize`:@@dropdown.noItemsFound:No items found`;

  @Input() options: T[] = [];
  @Input() placeholder = '';
  @Input() showBy!: Extract<keyof T, string>;
  @Input() trackBy!: Extract<keyof T, string>;
  @Input() groupBy: string = '';
  @Input() isNeedNgSelect = false;
  @Input() isSearchable = false;
  @Input() searchBy: Extract<keyof T, string>[] = [];
  @Input() value?: string | number | null;
  @Input() isCustomOption = true;
  @Input() isApiSearch = false;
  @Input() adminNgOptionClsName: boolean = false;

  @Output() onChange = new EventEmitter<any>();
  @Output() onScroll = new EventEmitter();
  @Output() onSearchOption = new EventEmitter<any>();

  @ContentChild(TemplateRef) templateRef: TemplateRef<any> | null = null;

  selValueModel: any = null;
  filteredOptions: T[] = [];
  onSearch = this.util.debounce(this.getSearchOption.bind(this), 800);
  constructor(private util: UtilFunctions) { }


  @ExceptionHandler()
  ngOnInit(): void
  {
    this.setSelectedValueModel();
  }


  @ExceptionHandler()
  ngOnChanges(
    changes: SimpleChanges
  ): void
  {
    if (!changes) { return; }

    const { options, value } = changes;

    if (options && options.currentValue)
    {
      this.options = options.currentValue;

      this.setSelectedValueModel();
    }

    if (!value) { return; }

    if (value.currentValue === null || value.currentValue === undefined)
    {
      this.selValueModel = null;
      return;
    }

    if (this.options.length)
    {
      this.value = value.currentValue;

      let isExist = false;

      for (const option of this.options)
      {
        if (option[this.trackBy] === this.value)
        {
          this.selValueModel = option;
          isExist = true;
        }
      }

      this.selValueModel = isExist ? this.selValueModel : null;
    }
    else
    {
      this.selValueModel = null;
    }
  }


  @ExceptionHandler()
  private setSelectedValueModel()
  {
    this.filteredOptions = this.options;

    if (this.value === null || this.value === undefined)
    {
      this.selValueModel = null;
      return;
    }

    for (const option of this.options)
    {
      if (option[this.trackBy] === this.value)
      {
        this.selValueModel = option;
        break;
      }
    }
  }


  @ExceptionHandler()
  private filterOptions(
    filterQuery: string
  )
  {
    let filteredOptions: T[] = [];

    filterQuery = filterQuery ? filterQuery.toLocaleLowerCase() : '';

    if (this.searchBy.length)
    {
      filteredOptions = this.options.filter(option =>
      {
        for (const searchBy of this.searchBy)
        {
          if (option[searchBy])
          {
            const fieldValue = String(option[searchBy]).toLowerCase();

            if (fieldValue.includes(filterQuery))
            {
              return true;
            }
          }
        }

        return false;
      });
    }
    else
    {
      filteredOptions = this.options.filter(option =>
      {
        if (option[this.showBy])
        {
          const fieldValue = String(option[this.showBy]).toLowerCase();

          return fieldValue.includes(filterQuery);
        }

        return false;
      });
    }

    return filteredOptions;
  }


  @ExceptionHandler()
  protected getSearchOption(
    event: {
      term: string;
      items: T[];
    }): void
  {
    const searchQuery = event.term;

    if (this.isApiSearch)
    {
      this.onSearchOption.emit(searchQuery);
    }
    else
    {
      this.filteredOptions = this.filterOptions(searchQuery);
    }

  }


  @ExceptionHandler()
  protected onSelection(
    event: any
  ): void
  {
    if (event)
    {
      if (event.target)
      {
        const value = event.target.value;

        for (const option of this.options)
        {
          if (option[this.trackBy] === value)
          {
            this.onChange.emit(option);
            this.selValueModel = option;
            break;
          }
        }
      }
      else
      {
        this.onChange.emit(event);
        this.selValueModel = event[this.showBy];
      }
    }
    else
    {
      this.filteredOptions = this.options;
    }
  }

  @ExceptionHandler()
  onScrollAddPage()
  {
    this.onScroll.emit();
  }
}


