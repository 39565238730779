import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, NgxsModuleOptions } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/auth.state';
import { ColorsState } from 'src/app/store/colors/colors.state';
import { LanguageState } from 'src/app/store/language/language.state';
import { MenuState } from 'src/app/store/menu/menu.state';
import { ScreenState } from 'src/app/store/screens/screens.state';
import { SettingsState } from 'src/app/store/settings/settings.state';
import { ShowcaseItemsState } from 'src/app/store/showcase/showcase.state';
import { environment } from '../../../environments/environment';
import { CommonState } from 'src/app/store/common/common.state';
import { AuthAdminState } from 'src/app/store/auth-admin/auth-admin.state';

const ngxsMainStates: any[] = [
  AuthState,
  ColorsState,
  CommonState,
  LanguageState,
  MenuState,
  ScreenState,
  SettingsState,
  ShowcaseItemsState,
  AuthAdminState
];

const ngxsConfig: NgxsModuleOptions = {
  developmentMode: !environment.production,
  compatibility: {
    strictContentSecurityPolicy: true,
  },
  selectorOptions: {
    injectContainerState: false,
    suppressErrors: false,
  }
};

export const ngxsImports = [
  NgxsFormPluginModule.forRoot(),
  NgxsRouterPluginModule.forRoot(),
  NgxsModule.forRoot(
    ngxsMainStates,
    ngxsConfig
  ),
  NgxsReduxDevtoolsPluginModule.forRoot({
    disabled: environment.production
  }),
  NgxsStoragePluginModule.forRoot({
    key: ngxsMainStates,
  }),
];
