import { Injectable, inject } from '@angular/core';
import { Media } from 'types-magic-api/dist/src/index.model';
import { lastValueFrom } from 'rxjs';
import { APIResponse } from 'src/app/model/api-response.model';
import { SERVICE_URL } from 'src/app/util/constant';
import { ExceptionHandler } from 'src/app/util/error-handler';
import { HttpService } from '../http/http.service';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private _logger = inject(LoggerService);

  constructor(private http: HttpService) {}

  @ExceptionHandler()
  public uploadFile(request: FormData): Promise<APIResponse<Media>> {
    const uploader$ = this.http.makeHttpPostRequest<FormData, Media>(
      SERVICE_URL.FILE_UPLOAD,
      request,
      true
    );

    return lastValueFrom(uploader$);
  }
}
