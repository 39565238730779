export const environment = {
  logAppId: 'dev.magicappui',
  loggerUrl: 'https://b2bapplog.ascent24.io/dev.magicappui',
  production: true,
  version: '1.0.9',
  apiUrl: 'https://api.wov.app/',
  paymentRedirectUrl: 'https://wov-payment.ascent24.io/payment',
  firebaseConfig: {
    apiKey: 'AIzaSyDekowMsf4-fqTkQE5iYjh9C4f71sgGaEo',
    authDomain: 'auth.wov.app',
    projectId: 'woww-apps',
    storageBucket: 'woww-apps.appspot.com',
    messagingSenderId: '813702147010',
    appId: '1:813702147010:web:a303fc9fb27e35b331445b',
    measurementId: 'G-Z5QWFEBW79',
  },
  clientId:
    '813702147010-tmqs0jsr31j1m95i7qik02976pr2g32v.apps.googleusercontent.com',
  scopes: ['profile', 'email'],
  grantOfflineAccess: true,
  awsBase: 'https://wovapp-live.s3.ap-south-1.amazonaws.com/',
  paymentUrl: 'https://buy.stripe.com/test_aEU6qh2XgdgD0h2cMM',
  orgId: 'd1237546-51f4-11ee-b16a-ff05701754db',
};
